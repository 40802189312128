import { useAppDispatch } from '../hooks/useAppDispatch';
import { withdrawRequest } from 'features/wallet/walletSlice';
import { useEffect } from 'react';

const WithdrawRequest = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(withdrawRequest(null));
  }, []);
  return <div>WithdrawRequest</div>;
};

export default WithdrawRequest;
