import { useEffect, useState } from 'react';
import { DashboardLayout } from '@layouts/DashboardLayout';
import { PageHeader, Pagination, Table } from 'components';
import { getAllUser, changePage, createAdmin } from 'features/users/UserSlice';
import { useAppDispatch, useAppSelector } from '../hooks';

const Admins = () => {
  const [role, setRole] = useState<string>('super');
  const [createRole, setCreateRole] = useState<boolean>(false);

  const { searchKey, data, page, numOfPages } = useAppSelector(
    (store) => store.user
  );
  const dispatch = useAppDispatch();
  const url = { role, page: page };
  const getRoleUser = (role: string) => {
    dispatch(getAllUser({ role, page }));
  };

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    role: '',
  });

  const [formErrors, setFormErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    role: '',
  });

  // const capitalize = (str:any) => str.charAt(0).toUpperCase() + str.slice(1);
  interface FormErrors {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    role: string;
  }
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Add form validation logic here if needed

    const errors: Partial<FormErrors> = {};
    const fields: (keyof FormErrors)[] = [
      'firstname',
      'lastname',
      'email',
      'phone',
      'role',
    ];

    fields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
      }
    });

    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      ...errors,
    }));

    if (Object.keys(errors).length === 0) {
      dispatch(createAdmin(formData));
      setFormData({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        role: '',
      });
    }
  };
  useEffect(() => {
    // if (searchKey) {
    //   dispatch(searchUser(searchKey));
    // } else {
    //   dispatch(getAllUser(url));
    // }
    if (!searchKey) {
      dispatch(getAllUser(url));
    }
    // dispatch(getAllUser(url));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchKey]);

  return (
    <DashboardLayout>
      <div className='container-fluid'></div>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <PageHeader
              headerLink={true}
              title='Admins'
              setRole={setRole}
              page={page}
              getRoleUser={getRoleUser}
            />

            <div className='col-auto mb-4'>
              <a
                onClick={() => setCreateRole((prevState) => !prevState)}
                className='btn btn-primary ms-2'
              >
                {createRole ? 'View Admins' : 'Create Admin'}
              </a>
            </div>
            {!createRole && (
              <Table
                data={data}
                th={[
                  'name',
                  'email',
                  'phone',
                  'account Status',
                  'verification Status',
                  'date Joined',
                ]}
                user
              />
            )}
          </div>
          {!createRole && numOfPages > 1 && (
            <Pagination
              page={page}
              numOfPages={numOfPages}
              changePage={changePage}
            />
          )}
        </div>
      </div>

      {/* form */}
      {createRole && (
        <div className='card'>
          <div className='card-body py-5'>
            <div className='row justify-content-between align-items-center'>
              <div className='col'>
                <div className='row align-items-center'>
                  <div className='col'>
                    <h4 className='mb-1'>Create new admin</h4>
                    <small className='text-muted'>
                      Admin will be assigned a role on creation.
                    </small>
                  </div>
                </div>
              </div>
              <div className='col-auto'>
                <a
                  onClick={() => setCreateRole(false)}
                  className='btn btn-sm btn-light'
                >
                  View admins
                </a>
              </div>
            </div>

            <hr className='my-5' />

            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-12 col-md-6 col-lg-4'>
                  <div className='form-group'>
                    <label htmlFor='firstname'>First Name</label>
                    <input
                      type='text'
                      className={`form-control ${
                        formErrors.firstname ? 'is-invalid' : ''
                      }`}
                      id='firstname'
                      name='firstname'
                      value={formData.firstname}
                      placeholder='Enter first name'
                      onChange={handleChange}
                    />
                    {formErrors.firstname && (
                      <div className='invalid-feedback'>
                        {formErrors.firstname}
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-12 col-md-6 col-lg-4'>
                  <div className='form-group'>
                    <label htmlFor='lastname'>Last Name</label>
                    <input
                      type='text'
                      className={`form-control ${
                        formErrors.lastname ? 'is-invalid' : ''
                      }`}
                      id='lastname'
                      name='lastname'
                      value={formData.lastname}
                      placeholder='Enter last name'
                      onChange={handleChange}
                    />
                    {formErrors.lastname && (
                      <div className='invalid-feedback'>
                        {formErrors.lastname}
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-12 col-md-6 col-lg-4'>
                  <div className='form-group'>
                    <label htmlFor='email'>Email Address</label>
                    <input
                      type='email'
                      className={`form-control ${
                        formErrors.email ? 'is-invalid' : ''
                      }`}
                      id='email'
                      name='email'
                      value={formData.email}
                      placeholder='Enter email address'
                      onChange={handleChange}
                    />
                    {formErrors.email && (
                      <div className='invalid-feedback'>{formErrors.email}</div>
                    )}
                  </div>
                </div>

                <div className='col-12 col-md-6 col-lg-4'>
                  <div className='form-group'>
                    <label htmlFor='phone'>Phone</label>
                    <input
                      type='tel'
                      className={`form-control ${
                        formErrors.phone ? 'is-invalid' : ''
                      }`}
                      id='phone'
                      name='phone'
                      value={formData.phone}
                      placeholder='Enter mobile number'
                      onChange={handleChange}
                    />
                    {formErrors.phone && (
                      <div className='invalid-feedback'>{formErrors.phone}</div>
                    )}
                  </div>
                </div>

                <div className='col-12 col-md-6 col-lg-4'>
                  <div className='form-group'>
                    <label htmlFor='role'>Role</label>
                    <select
                      className={`form-control ${
                        formErrors.role ? 'is-invalid' : ''
                      }`}
                      id='role'
                      name='role'
                      value={formData.role}
                      onChange={handleChange}
                    >
                      <option value=''>Select role</option>
                      {/* {roles
                      .sort((a, b) => a.priority - b.priority)
                      .map((role) => (
                        <option key={role.name} value={role.name}>
                          {capitalize(role.name)}
                        </option>
                      ))} */}
                      <option value='support'>Support</option>
                      <option value='legal'>Legal</option>
                      <option value='finance'>Finance</option>
                    </select>
                    {formErrors.role && (
                      <div className='invalid-feedback'>{formErrors.role}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row justify-content-end'>
                <div className='col-auto'>
                  <button type='submit' className='btn btn-primary'>
                    Create
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Admins;
