// import { useAppDispatch } from '../hooks';
// import { Link } from 'react-router-dom';

import moment from 'moment';

const WalletTransactionTableData = ({ data }: any) => {
  // const dispatch = useAppDispatch();

  return (
    <tbody className='list fs-base'>
      {data?.map((info: any) => {
        const {
          processedBy,
          initiatedAt,
          status,
          type,
          destination,
          transaction,
          amount,

          id,
        } = info;

        return (
          <tr key={id}>
            <td>
              <div className='form-check'>
                <input
                  className='form-check-input list-checkbox'
                  id='listCheckboxOne'
                  type='checkbox'
                />
                <label
                  className='form-check-label'
                  htmlFor='listCheckboxOne'
                ></label>
              </div>
            </td>
            <td>
              {/* <Link to={`/transactions/${id}`}>{id}</Link> */}
              {id}
            </td>
            <td>
              <span className='item-intent'>NGN {amount.toLocaleString()}</span>
            </td>
            <td>
              <span className='item-intent'>{type}</span>
            </td>
            <td>
              <span className='item-bedrooms'>{destination}</span>
            </td>
            <td className='orders-status'>
              {status === 'Paid' ? (
                <div className='badge bg-success-soft'>{status}</div>
              ) : status === 'completed' ? (
                <div className='badge bg-primary-soft'>Completed</div>
              ) : status === 'in-progress' ? (
                <div className='badge bg-warning-soft'>In Progress</div>
              ) : (
                <div className='badge bg-danger-soft'>{status}</div>
              )}
            </td>
            <td>
              <span className='item-price'>{processedBy?.name}</span>
            </td>
            <td>
              <span className='item-cycle'>
                {moment(initiatedAt).format('MMM Do YY')}
              </span>
            </td>
            <td>
              <span className='item-user'>{transaction}</span>
            </td>

            {/* <span className='item-createdAt text-reset'>{status}</span> */}

            {/* <td className='text-end'>
              <div className='dropdown'>
                <a
                                  className='dropdown-ellipses dropdown-toggle'
                                  href='#!'
                                  role='button'
                                  data-bs-toggle='dropdown'
                                  aria-haspopup='true'
                                  aria-expanded='false'
                                >
                                  <i className='fe fe-more-vertical'></i>
                                </a>

                <button
                  className=' dropdown-ellipses dropdown-toggle btn btn-link'
                  id='dropdownMenuButton1'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                  type='button'
                >
                  <i className='fe fe-more-vertical'></i>
                </button>
                <div className='dropdown-menu dropdown-menu-end'>
                  <Link to={`/properties/${id}`} className='dropdown-item'>
                    Edit
                  </Link>
                  <button
                    className='dropdown-item btn btn-link'
                    onClick={() => {
                      dispatch(deleteProperty(id));
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </td> */}
          </tr>
        );
      })}
    </tbody>
  );
};

export default WalletTransactionTableData;
