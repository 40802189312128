import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardLayout } from '@layouts/DashboardLayout';
// import { TransactionCard } from '@shared/TransactionCard';
import {
  getAllTransactions,
  setPage,
  //  getTransactionsById,
  //  getTransactionsByWalletId
} from 'features/wallet/walletSlice';
import { useAppSelector } from '../hooks/useAppSelector';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { paginate } from '@utils/pagination';
import { PageHeader, Table } from 'components';

const WalletTransactions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, allTransactions, page } = useAppSelector(
    (store) => store.wallet
  );

  useEffect(() => {
    dispatch(getAllTransactions(null));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || allTransactions === null || allTransactions === null) {
    return <h1>Loading</h1>;
  }

  const pageData = paginate(allTransactions, 5);

  const handlePrev = () => {
    let num;
    num = page - 1;
    if (num < 0) {
      num = pageData.length - 1;
    }
    dispatch(setPage(num));
  };

  const handleNext = () => {
    let num;
    num = page + 1;
    if (num > pageData.length - 1) {
      num = 0;
    }

    dispatch(setPage(num));
  };

  return (
    <DashboardLayout>
      <div className='container-fluid'>
        <button
          className='btn btn-dark mt-4'
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </button>
      </div>
      {/* <div className='container mt-5 px-5'>
				{pageData[page].map((transaction: any, index: number) => (
					<TransactionCard transaction={transaction} key={index} />
				))}
				
			</div> */}

      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <PageHeader headerLink={false} title='Wallet Transactions' />

            <Table
              data={allTransactions}
              th={[
                'id',
                'amount',
                'type',
                'destination',
                'status',
                'processed by',
                'initiated at',
                'transaction id',
              ]}
              walletTransactions
            />
          </div>
        </div>
      </div>

      {/* break */}
      <div className='row g-0'>
        {/* <!-- Pagination (prev) --> */}
        <div className='col list-pagination-prev pagination pagination-tabs justify-content-start'>
          {/* <li className='page-item' onClick={prev}> */}
          <span className='page-item ' onClick={handlePrev}>
            <button className='page-link'>
              <i className='fe fe-arrow-left ms-1'></i>
              Previous
            </button>
          </span>
          {/* </li> */}
        </div>

        {/* <!-- Pagination --> */}
        <ul className='col list-pagination pagination pagination-tabs justify-content-center'>
          {pageData.map((_, index) => {
            return (
              <li
                className={page === index ? 'page-item active' : 'page-item'}
                key={index}
              >
                <button
                  className='page-link'
                  onClick={() => {
                    dispatch(setPage(index));
                  }}
                >
                  {index + 1}
                </button>
              </li>
            );
          })}
        </ul>

        {/* <!-- Pagination (next) --> */}
        <div className='col list-pagination-next pagination pagination-tabs justify-content-end'>
          {/* <li className='page-item'>
                        <a className='page-link' href='#'>
                          Next <i className='fe fe-arrow-right ms-1'></i>
                        </a>
                      </li> */}
          <span className='page-item'>
            <button className='page-link' onClick={handleNext}>
              Next <i className='fe fe-arrow-right ms-1'></i>
            </button>
          </span>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default WalletTransactions;
