import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import customFetch, { checkUnauthorizedResponse } from '@utils/axios';
import { toast } from 'react-toastify';

interface AsyncState {
  isLoading: boolean;
}

interface AuthState extends AsyncState {
  success?: boolean;
  data?: any | null;
  total?: number;
  page?: number;
  page_count?: number;
  pagination?: any;
  title?: any | null;
  icon?: any | null;
  offers?: any | null;
}

const initialDataState = {
  title: '',
  icon: '',
  offers: [],
};

const initialState: AuthState = {
  data: null,
  isLoading: false,
  success: false,
  page_count: 1,
  page: 1,
  total: 1,
  ...initialDataState,
};

// Get All Amenities
export const getAllAmenities = createAsyncThunk(
  'amenities/allAmenities',
  async (thunkApi: any): Promise<unknown> => {
    try {
      const resp = await customFetch.get('/amenities');

      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);
// Create Amenities
export const CreateAmenities = createAsyncThunk(
  'amenities/createAmenities',
  async (
    data: { title: string; icons: string; offers: any },
    thunkApi: any
  ): Promise<unknown> => {
    // const data = thunkApi.getState();
    // const { title, icon, offers } = data.amenities;
    try {
      const resp = await customFetch.post('/amenities', data);

      thunkApi.dispatch(getAllAmenities({}));
      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);

// Delete Amenities
export const deleteAmenities = createAsyncThunk(
  'amenities/deleteAmenities',
  async (id: string, thunkApi: any): Promise<unknown> => {
    try {
      const resp = await customFetch.delete(`/amenities/${id}`);
      thunkApi.dispatch(getAllAmenities({}));

      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);

const AmenitiesSlice = createSlice({
  name: 'amenities',
  initialState,
  reducers: {
    handleAmenitiesChange: (state: any, { payload }: any) => {
      const { name, value } = payload;
      state[name] = value;
    },
  },
  extraReducers: (builder) => {
    // Get All Amenities
    builder.addCase(getAllAmenities.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllAmenities.fulfilled,
      (state: any, { payload }: any) => {
        const { page_count, data, pagination, success, total } = payload;

        state.data = data;
        state.page_count = page_count;
        state.pagination = pagination;
        state.success = success;
        state.isLoading = false;
        state.total = total;
      }
    );
    builder.addCase(getAllAmenities.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Create Amenities
    builder.addCase(CreateAmenities.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CreateAmenities.fulfilled, (state: any) => {
      state.isLoading = false;
      toast.success('Amenities Added Successfully');
    });
    builder.addCase(CreateAmenities.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
    // Delete Amenities
    builder.addCase(deleteAmenities.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAmenities.fulfilled, (state: any) => {
      state.isLoading = false;
      toast.success('Amenities deleted Successfully');
    });
    builder.addCase(deleteAmenities.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
  },
});
export const { handleAmenitiesChange } = AmenitiesSlice.actions;
export default AmenitiesSlice.reducer;
