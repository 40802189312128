// import { useAppDispatch } from '../hooks';
import { Link } from 'react-router-dom';

import moment from 'moment';

const TransactionTableData = ({ data }: any) => {
  // const dispatch = useAppDispatch();

  return (
    <tbody className='list fs-base'>
      {data?.map((info: any) => {
        const {
          arrival_date,
          createdAt,
          departure_date,
          inspected,
          inspection_date,
          id,
          owner,
          property_avalability_status,
          status,
          listing,
        } = info;
        return (
          <tr key={id}>
            <td>
              <div className='form-check'>
                <input
                  className='form-check-input list-checkbox'
                  id='listCheckboxOne'
                  type='checkbox'
                />
                <label
                  className='form-check-label'
                  htmlFor='listCheckboxOne'
                ></label>
              </div>
            </td>
            <td>
              <Link to={`/transactions/${id}`}>
                {listing?.title.length > 0 &&
                  listing?.title.slice(0, 20) + '...'}
              </Link>
            </td>
            <td>
              <span className='item-intent'>{owner.name}</span>
            </td>
            <td>
              <span className='item-intent'>{listing?.intent}</span>
            </td>
            <td>
              <span className='item-bedrooms'>
                {moment(arrival_date).format('MMM Do YY')}
              </span>
            </td>
            <td>
              <span className='item-location'>
                {moment(departure_date).format('MMM Do YY')}
              </span>
            </td>
            <td>
              <span className='item-price'>
                {inspected === false ? 'No' : 'Yes'}
              </span>
            </td>
            <td>
              <span className='item-cycle'>
                {moment(inspection_date).format('MMM Do YY')}
              </span>
            </td>
            <td>
              <span className='item-user'>{property_avalability_status}</span>
            </td>
            <td>
              <span className='item-createdAt text-reset'>
                {moment(createdAt).format('MMM Do YY')}
              </span>
            </td>

            {/* <span className='item-createdAt text-reset'>{status}</span> */}
            <td className='orders-status'>
              {status === 'customer-accepted' ? (
                <div className='badge bg-primary-soft'>Accepted</div>
              ) : status === 'completed' ? (
                <div className='badge bg-success-soft'>Completed</div>
              ) : status === 'in-progress' ? (
                <div className='badge bg-warning-soft'>In Progress</div>
              ) : (
                <div className='badge bg-danger-soft'>{status}</div>
              )}
            </td>

            {/* <td className='text-end'>
              <div className='dropdown'>
                <a
                                  className='dropdown-ellipses dropdown-toggle'
                                  href='#!'
                                  role='button'
                                  data-bs-toggle='dropdown'
                                  aria-haspopup='true'
                                  aria-expanded='false'
                                >
                                  <i className='fe fe-more-vertical'></i>
                                </a>

                <button
                  className=' dropdown-ellipses dropdown-toggle btn btn-link'
                  id='dropdownMenuButton1'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                  type='button'
                >
                  <i className='fe fe-more-vertical'></i>
                </button>
                <div className='dropdown-menu dropdown-menu-end'>
                  <Link to={`/properties/${id}`} className='dropdown-item'>
                    Edit
                  </Link>
                  <button
                    className='dropdown-item btn btn-link'
                    onClick={() => {
                      dispatch(deleteProperty(id));
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </td> */}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TransactionTableData;
