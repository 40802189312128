import { useAppDispatch } from '../../../hooks/useAppDispatch';
// import { useAppSelector } from '../../../hooks/useAppSelector';
import { logoutUser, updateUserPage } from 'features/users/UserSlice';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import img from '../../../images/user.jpeg';

// import { FaWallet } from 'react-icons/fa';
// import { GiFamilyHouse } from 'react-icons/gi';
// import { FaUsers, FaBusinessTime } from 'react-icons/fa';
// import { MdRealEstateAgent, MdOutlineReviews } from 'react-icons/md';
// import { BsPersonCheckFill } from 'react-icons/bs';
// import { RiAdminLine } from 'react-icons/ri';

import logo from '../../../images/Klogob.png';
export const DashboardSideBar = () => {
  // const { loggedInUser } = useAppSelector((store) => store.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/sign-in');
  };
  // adding this update page change was neccesary after dicovering pages remains the same switching from another user

  const handleUpdatePage = () => {
    dispatch(updateUserPage(1));
  };

  return (
    <nav
      className='navbar navbar-vertical navbar-light fixed-start navbar-expand-md '
      id='sidebar'
    >
      <div className='container-fluid'>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#sidebarCollapse'
          aria-controls='sidebarCollapse'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <a className='navbar-brand' href='#!'>
          <img
            src={logo}
            className='navbar-brand-img mx-auto'
            alt='krent'
            style={{ width: '130px' }}
          />
        </a>
        <div className='navbar-user d-md-none'>
          <div className='dropdown'>
            <span
              className='avatar avatar-md avatar-online dropdown-toggle'
              role='button'
              data-bs-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <img src={img} alt='...' className='avatar-img rounded-circle' />
              {/* <h6>
                {loggedInUser?.firstname} {loggedInUser?.lastname}
              </h6> */}
            </span>

            <div className='dropdown-menu dropdown-menu-end'>
              {/* <a href='profile-posts.html' className='dropdown-item'>
                  Profile
                </a>
                <a href='account-general.html' className='dropdown-item'>
                  Settings
                </a> */}
              <hr className='dropdown-divider' />
              <button className='dropdown-item' onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>

        <div className='collapse navbar-collapse' id='sidebarCollapse'>
          <form className='mt-4 mb-3 d-md-none'>
            <div className='input-group input-group-rounded input-group-merge input-group-reverse'>
              <input
                className='form-control'
                type='search'
                placeholder='Search'
                aria-label='Search'
              />
              <div className='input-group-text'>
                <span className='fe fe-search'></span>
              </div>
            </div>
          </form>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/summary/'>
                <i className='fe fe-home'></i> Summary
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/properties/'>
                <i className='fe fe-list'></i> Properties
                {/* <FaHouseUser /> Properties */}
                {/* <GiFamilyHouse /> Properties */}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                className='nav-link'
                to='/customers/'
                onClick={handleUpdatePage}
              >
                <i className='fe fe-users'></i> Customers
                {/* <FaUsers /> Customers */}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                className='nav-link'
                to='/landlords/'
                onClick={handleUpdatePage}
              >
                <i className='fe fe-user'></i> Landlords
                {/* <MdRealEstateAgent /> Landlords */}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                className='nav-link'
                to='/agents/'
                onClick={handleUpdatePage}
              >
                <i className='fe fe-user-plus'></i> Agents
                {/* <BsPersonCheckFill /> Agents */}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                className='nav-link'
                to='/admins/'
                onClick={handleUpdatePage}
              >
                <i className='fe fe-alert-triangle'></i> Admins
                {/* <RiAdminLine /> Admin */}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/transactions/'>
                <i className='fe fe-dollar-sign'></i> Transactions
                {/* <FaBusinessTime /> Transactions */}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/reviews/'>
                <i className='fe fe-star'></i> Reviews
                {/* <MdOutlineReviews /> Reviews */}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/reviews/'>
                <i className='fe fe-activity'></i> Analysis
                {/* <MdOutlineReviews /> Analysis */}
              </NavLink>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                href='#sidebarWallet'
                data-bs-toggle='collapse'
                role='button'
                aria-expanded='false'
                aria-controls='sidebarWallet'
              >
                <i className='fe fe-credit-card'></i> Wallet
                {/* <FaWallet /> Wallet */}
              </a>
              <div className='collapse' id='sidebarWallet'>
                <ul className='nav nav-sm flex-column'>
                  <li className='nav-item'>
                    <NavLink className='nav-link ' to={'/wallet/transactions'}>
                      Transactions
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to={'/wallet/withdraw-request'}
                      className='nav-link '
                    >
                      Withdrawal request
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                href='#websiteContent'
                data-bs-toggle='collapse'
                role='button'
                aria-expanded='false'
                aria-controls='websiteContent'
              >
                <i className='fe fe-credit-card'></i> Edit Web Content
                {/* <FaWallet /> Wallet */}
              </a>
              <div className='collapse' id='websiteContent'>
                <ul className='nav nav-sm flex-column'>
                  <li className='nav-item'>
                    <NavLink className='nav-link ' to={'/web/categories'}>
                      Categories
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink to={'/web/kind'} className='nav-link '>
                      Kind and Space
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink to={'/web/amenities'} className='nav-link '>
                      Amenities
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <div className='navbar-user d-none d-md-flex' id='sidebarUser'>
            <a
              className='navbar-user-link'
              data-bs-toggle='offcanvas'
              href='#sidebarOffcanvasActivity'
              aria-controls='sidebarOffcanvasActivity'
            >
              <span className='icon'>
                <i className='fe fe-bell'></i>
              </span>
            </a>

            <div className='dropdown'>
              <span
                className='avatar avatar-md avatar-online dropdown-toggle'
                role='button'
                data-bs-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <img
                  src={img}
                  alt='...'
                  className='avatar-img rounded-circle'
                />
                {/* <h6>
                {loggedInUser?.firstname} {loggedInUser?.lastname}
              </h6> */}
              </span>

              <div className='dropdown-menu dropdown-menu-end'>
                {/* <a href='profile-posts.html' className='dropdown-item'>
                  Profile
                </a>
                <a href='account-general.html' className='dropdown-item'>
                  Settings
                </a> */}
                <hr className='dropdown-divider' />
                <button className='dropdown-item' onClick={handleLogout}>
                  Logout
                </button>
              </div>
            </div>

            <a
              className='navbar-user-link'
              data-bs-toggle='offcanvas'
              href='#sidebarOffcanvasSearch'
              aria-controls='sidebarOffcanvasSearch'
            >
              <span className='icon'>
                <i className='fe fe-search'></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};
