import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import customFetch, { checkUnauthorizedResponse } from '@utils/axios';
import { toast } from 'react-toastify';

import { DisplayReview } from './DisplayReview.interface';

interface AsyncState {
  isLoading: boolean;
}

interface AuthState extends AsyncState {
  success?: boolean;
  data?: DisplayReview[] | null;
  total?: number;
  page?: number;
  numOfPages?: number;
  page_count?: number;
  pagination?: any;
  singleReview?: any;
}

const initialState: AuthState = {
  data: null,
  singleReview: null,
  isLoading: false,
  success: false,
  page_count: 1,
  page: 1,
  numOfPages: 1,
  total: 0,
};

// All Review
export const fetchAllReviews = createAsyncThunk(
  'reviews/allReviews',
  async (thunkApi: any): Promise<unknown> => {
    try {
      const resp = await customFetch.get('/reviews');
      console.log(resp.data);

      return resp.data;
    } catch (error: any) {
      return checkUnauthorizedResponse(error, thunkApi);
    }
  }
);

// Fetch Single Review
export const fetchSingleReview = createAsyncThunk(
  'reviews/singleReview',
  async (id: string, thunkApi: any): Promise<unknown> => {
    try {
      const resp = await customFetch.get(`/reviews/${id}`);
      console.log(resp.data);

      return resp.data.data;
    } catch (error: any) {
      return checkUnauthorizedResponse(error, thunkApi);
    }
  }
);

const ReviewSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    changePage: (state: any, { payload }: any) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    // fetch All Reviews
    builder.addCase(fetchAllReviews.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchAllReviews.fulfilled,
      (state: any, { payload }: any) => {
        const { page_count, data, pagination, success, total } = payload;

        state.data = data;
        state.page_count = page_count;
        state.pagination = pagination;
        state.success = success;
        state.total = total;
        state.numOfPages = Math.ceil(total / 20);
        state.isLoading = false;
      }
    );
    builder.addCase(fetchAllReviews.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // fetch Single Reviews
    builder.addCase(fetchSingleReview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchSingleReview.fulfilled,
      (state: any, { payload }: any) => {
        state.singleReview = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchSingleReview.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
  },
});

export default ReviewSlice.reducer;
export const { changePage } = ReviewSlice.actions;
