// const updateArray = (newItem: any, oldArray: any) => {
//   let found = false;
//   let index = 0;
//   // eslint-disable-next-line
//   oldArray.map((item: any, i: number) => {
//     if (item.title === newItem.title) {
//       found = true;
//       index = i;
//     }
//   });
//   if (found) {
//     oldArray[index] = newItem;
//   } else {
//     oldArray.push(newItem);
//   }

//   return oldArray;
// };

const updateArray = (newItem: any, oldArray: any[]) => {
  if (!Array.isArray(oldArray)) {
    return [newItem];
  }
  const index = oldArray.findIndex((item: any) => item.title === newItem.title);

  if (index !== -1) {
    // If the item is found, create a new array with the updated item
    const newArray = [...oldArray];
    newArray[index] = newItem;
    return newArray;
  } else {
    // If the item is not found, create a new array by pushing the new item
    return [...oldArray, newItem];
  }
};
export default updateArray;
