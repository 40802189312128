import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import {
  changePage,
  fetchAllTransactions,
} from 'features/transactions/TransactionsSlice';
import { useEffect } from 'react';

import { DashboardLayout } from '@layouts/DashboardLayout';
import { PageHeader, Pagination, Table } from 'components';
const Transactions = () => {
  const dispatch = useAppDispatch();
  const { isLoading, data, page, numOfPages } = useAppSelector(
    (store) => store.transactions
  );

  useEffect(() => {
    dispatch(fetchAllTransactions({ page }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || data === null) {
    return <h1>Loading</h1>;
  }
  return (
    <DashboardLayout>
      <div className='container-fluid'></div>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <PageHeader headerLink={false} title='Transactions' />

            <Table
              data={data}
              th={[
                'title',
                'owner',
                'intent',
                'Arrival Date',
                'Departure Date',
                'inspected',
                'inspection date',
                'availability',
                'Creation date',
                'status',
              ]}
              transactions
            />

            {numOfPages! > 1 && (
              <Pagination
                page={page}
                numOfPages={numOfPages}
                changePage={changePage}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Transactions;
