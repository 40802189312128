import { useEffect } from 'react';

type Props = {
	setShowModal?: (showModal: boolean) => void;
	setTotalSteps: Function;
	index: number;
	children: JSX.Element[];
};

export const NewPropertyLayout = ({
	children,
	index,
	setTotalSteps,
}: Props) => {
	// useEffect(() => {
	// 	const forms = document.querySelectorAll('.needs-validation');

	// 	// Loop over them and prevent submission
	// 	Array.from(forms).forEach((form: any) => {
	// 		form.addEventListener(
	// 			'submit',
	// 			(event: React.FormEvent<HTMLFormElement>) => {
	// 				if (!form.checkValidity()) {
	// 					event.preventDefault();
	// 					event.stopPropagation();
	// 				}

	// 				form.classList.add('was-validated');
	// 			},
	// 			false
	// 		);
	// 	});

	// 	// TODO: Add clean up function
	// }, [index]);

	useEffect(() => {
		setTotalSteps(children.length);
	});

	return (
		<div className='container-lg'>
			<div className='row justify-content-center'>
				<div className='col-12 col-lg-10 col-xl-8'>{children[index]}</div>
			</div>
		</div>
	);
};
