import { useAppSelector } from '../hooks/useAppSelector';
import { useAppDispatch } from '../hooks/useAppDispatch';
// import {
//   handlePropertyChange,
//   clearFilters,
//   fetchAllProperties,
// } from 'features/properties/PropertiesSlice';
import { handleUserOnChange, searchUser } from 'features/users/UserSlice';
const UserSearchBar = () => {
  const dispatch = useAppDispatch();
  // const properties = useAppSelector((store) => {
  //   return store.properties;
  // });

  // const { bedrooms, intent, isLoading } = properties;
  // const location = properties['location.city'];

  const { searchKey } = useAppSelector((store) => store.user);

  // const handleFilter = (e: any) => {
  //   if (isLoading) return;
  //   dispatch(
  //     handlePropertyChange({ name: e.target.name, value: e.target.value })
  //   );
  // };
  const handleSearch = (e: any) => {
    // if (isLoading) return;
    // dispatch(searchUser(e.target.value));
    dispatch(
      handleUserOnChange({ name: e.target.name, value: e.target.value })
    );
  };

  // const handleSubmit = (e: any) => {
  //   e.preventDefault();

  //   let query;
  //   if (intent) {
  //     query = { page: 1, intent };
  //   }
  //   if (bedrooms) {
  //     query = { ...query, bedrooms };
  //   }
  //   if (location) {
  //     query = { ...query, 'location.city': location };
  //   }
  //   dispatch(fetchAllProperties(query));
  //   dispatch(clearFilters());
  // };
  return (
    <div className='card-header'>
      <div className='row align-items-center'>
        <div className='col'>
          <form>
            <div className='input-group input-group-flush input-group-merge '>
              <input
                className='form-control list-search'
                type='text'
                name='searchKey'
                value={searchKey}
                onChange={handleSearch}
                placeholder='Search'
              />
              {/* <span className='input-group-text btn'>
                <i className='fe fe-search'></i>
              </span> */}

              <button
                type='button'
                className='btn btn-outline-primary'
                onClick={() => dispatch(searchUser(searchKey))}
              >
                search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserSearchBar;
