import { useState } from 'react';

const PageHeader = ({
  // link1,
  // link2,

  headerLink = false,
  title,
  // total,
  getPublished,
  getUnpublished,
  getAllProperties,
  getRoleUser,
}: any) => {
  const [active, setActive] = useState(title === 'Admins' ? 'super' : 'all');

  type TabInfo = {
    [key: string]: { id: string; label: string }[];
  };

  type Actions = {
    [key: string]: () => void;
  };

  const tabInfo: TabInfo = {
    Properties: [
      { id: 'all', label: 'All Properties' },
      { id: 'published', label: 'Published' },
      { id: 'unpublished', label: 'Unpublished' },
    ],
    Admins: [
      { id: 'super', label: 'Super Admins' },
      { id: 'support', label: 'Support Admins' },
      { id: 'legal', label: 'Legal Admins' },
      { id: 'finance', label: 'Finance Admins' },
    ],
  };

  const handleTabClick = (tab: string) => {
    setActive(tab);
    // Perform actions based on the tab clicked
    if (title === 'Properties') {
      const propertyActions: Actions = {
        all: getAllProperties,
        published: getPublished,
        unpublished: getUnpublished,
      };
      const selectedAction = propertyActions[tab];
      if (selectedAction) {
        selectedAction();
      }
    } else if (title === 'Admins') {
      const adminActions: Actions = {
        super: () => getRoleUser('super'),
        support: () => getRoleUser('support'),
        legal: () => getRoleUser('legal'),
        finance: () => getRoleUser('finance'),
      };
      const selectedAction = adminActions[tab];
      if (selectedAction) {
        selectedAction();
      }
    }
  };

  return (
    <div className='header'>
      <div className='header-body'>
        <div className='row align-items-center'>
          <div className='col'>
            <h6 className='header-pretitle'>Overview</h6>
            <h1 className='header-title text-truncate'>{title}</h1>
          </div>
        </div>
        {headerLink && (
          <div className='row align-items-center'>
            <div className='col'>
              <ul className='nav nav-tabs nav-overflow header-tabs'>
                {tabInfo[title].map((tab) => (
                  <li className='nav-item' key={tab.id}>
                    <button
                      className={`nav-link text-nowrap ${
                        active === tab.id ? 'active' : ''
                      }`}
                      onClick={() => handleTabClick(tab.id)}
                    >
                      {tab.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
