import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import customFetch, { checkUnauthorizedResponse } from '@utils/axios';
import { toast } from 'react-toastify';

interface AsyncState {
  isLoading: boolean;
}

interface AuthState extends AsyncState {
  success?: boolean;
  data?: any | null;
  spaceData?: any | null;
  total?: number;
  page?: number;
  page_count?: number;
  pagination?: any;
  kindTitle?: any | null;
  spaceTitle?: any | null;
}

const initialState: AuthState = {
  data: null,
  spaceData: null,
  isLoading: false,
  success: false,
  page_count: 1,
  page: 1,
  total: 1,
  kindTitle: '',
  spaceTitle: '',
};

// Get All Kinds
export const getAllKinds = createAsyncThunk(
  'kinds/allKinds',
  async (thunkApi: any): Promise<unknown> => {
    try {
      const resp = await customFetch.get('/kinds');

      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);
// Create Kind
export const CreateKind = createAsyncThunk(
  'kind/createKind',
  async (data: { title: string }, thunkApi: any): Promise<unknown> => {
    // const data = thunkApi.getState();
    // const { kindTitle } = data.kind;

    try {
      const resp = await customFetch.post('/kinds', data);
      thunkApi.dispatch(getAllKinds({}));
      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);

// Delete Kind
export const deleteKind = createAsyncThunk(
  'kind/deleteKind',
  async (id: string, thunkApi: any): Promise<unknown> => {
    try {
      const resp = await customFetch.delete(`/kinds/${id}`);
      thunkApi.dispatch(getAllKinds({}));

      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);

// FETCHING FOR SPACE

// Get All Space
export const getAllSpaces = createAsyncThunk(
  'space/allSpace',
  async (thunkApi: any): Promise<unknown> => {
    try {
      const resp = await customFetch.get('/spaces');

      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);
// Create Space
export const CreateSpace = createAsyncThunk(
  'space/createSpace',
  async (data: { title: string }, thunkApi: any): Promise<unknown> => {
    // const data = thunkApi.getState();
    // const { spaceTitle } = data.kind;

    try {
      const resp = await customFetch.post('/spaces', data);
      thunkApi.dispatch(getAllSpaces({}));

      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);

// Delete Space
export const deleteSpace = createAsyncThunk(
  'space/deleteSpace',
  async (id: string, thunkApi: any): Promise<unknown> => {
    try {
      const resp = await customFetch.delete(`/spaces/${id}`);
      thunkApi.dispatch(getAllSpaces({}));

      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);

const KindSlice = createSlice({
  name: 'kind',
  initialState,
  reducers: {
    handleKindsChange: (state: any, { payload }: any) => {
      const { name, value } = payload;
      state[name] = value;
    },
  },
  extraReducers: (builder) => {
    // Get All Kind
    builder.addCase(getAllKinds.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllKinds.fulfilled, (state: any, { payload }: any) => {
      const { page_count, data, pagination, success, total } = payload;

      state.data = data;
      state.page_count = page_count;
      state.pagination = pagination;
      state.success = success;
      state.isLoading = false;
      state.total = total;
    });
    builder.addCase(getAllKinds.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Create Kind
    builder.addCase(CreateKind.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CreateKind.fulfilled, (state: any, { payload }: any) => {
      state.isLoading = false;
      toast.success(payload);
    });
    builder.addCase(CreateKind.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Delete Kind
    builder.addCase(deleteKind.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteKind.fulfilled, (state: any) => {
      state.isLoading = false;
      toast.success('Property Kind deleted Successfully');
    });
    builder.addCase(deleteKind.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // SPACES EXTRA REDUCERS

    // Get All Space
    builder.addCase(getAllSpaces.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllSpaces.fulfilled, (state: any, { payload }: any) => {
      const { data } = payload;

      state.spaceData = data;
    });
    builder.addCase(getAllSpaces.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Create Space
    builder.addCase(CreateSpace.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CreateSpace.fulfilled, (state: any, { payload }: any) => {
      state.isLoading = false;
      toast.success(payload);
    });
    builder.addCase(CreateSpace.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
    // Delete Space
    builder.addCase(deleteSpace.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteSpace.fulfilled, (state: any) => {
      state.isLoading = false;
      toast.success('Property Space Deleted Successfully');
    });
    builder.addCase(deleteSpace.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
  },
});

export default KindSlice.reducer;
export const { handleKindsChange } = KindSlice.actions;
