// import { InputComponent } from '@shared/forms';
// import { PrimaryButton } from '@shared/buttons';
// import { formatCurrency } from '@utils/currency';
// import { CycleToInterval } from '@utils/constants';

type Props = {
	currency: string;
	price: number;
	type: string;
	duration: string;
};

export const PriceCard: React.FC<Props> = ({
	price,
	type,
	duration,
	currency,
}: Props) => {
	// const [date, setDate] = useState((new Date()).toDateString());

	return (
		<div className='border-1 rounded border-purple'>
			<div className='d-flex flex-column p-4 h-100'>
				<div className='d-flex flex-column'>
					<p className='light-text'>
						{type === 'rent' ? 'Rent' : type === 'iBuying' ? 'iBuying' : 'Sale'}{' '}
						Price
					</p>
					<div className='d-flex flex-row justify-content-between align-items-center mt-neg'>
						<p className='fs-12'>
							<span className='fs-22 fw-bolder'>
								{currency} {price.toLocaleString()}
							</span>
							/{duration}
						</p>
						{type === 'sale' && (
							<span className='badge d-inline-flex fs-12 mb-3 px-3 py-2 fw-semibold text-white bg-custom-royal bg-opacity-10 border border-success border-opacity-10 rounded-pill'>
								FOR SALE
							</span>
						)}
						{type === 'rent' && (
							<span className='badge d-inline-flex fs-12 mb-3 px-3 py-2 fw-semibold text-white bg-success bg-opacity-10 border border-success border-opacity-10 rounded-pill'>
								RENTAL
							</span>
						)}
						{type === 'long lease' && (
							<span className='badge d-inline-flex fs-12 mb-3 px-3 py-2 fw-semibold text-white bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-pill'>
								SHORT LET
							</span>
						)}
						{type === 'iBuying' && (
							<span className='badge d-inline-flex fs-12 mb-3 px-3 py-2 fw-semibold text-white bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-pill'>
								INSTANT BUYING
							</span>
						)}
					</div>
				</div>

				<hr />

				{/* <div className=''>
					<h5 className='fw-bold mb-2'>Request a home tour</h5>

					<InputComponent
						type='date'
						placeholder='Select tour date'
						icon='Calendar'
						value={date}
						change={(e) => {
							setDate(e.target.value);
						}}
					/>
					<div className='d-grid my-4'>
						<PrimaryButton type='primary' text='Request a tour' />
					</div>

					<p className='text-secondary fs-12'>
						This will also notify the person who listed this space. It’s free of
						charge.
					</p>
				</div> */}
			</div>
		</div>
	);
};
