import { useAppDispatch } from '../../hooks/useAppDispatch';
import Select from 'react-select';
import {
  createProperty,
  handlePropertyChange,
  addPropertyImage,
  updateProperty,
} from 'features/properties/PropertiesSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { getAllAmenities } from 'features/amenities/amenitiesSlice';
import { getAllCategories } from 'features/categories/categorySlice';
import { getAllKinds, getAllSpaces } from 'features/kind/kindSlice';
import { Amenities } from 'features/properties/EditProperty.interface';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import updateArray from '@utils/updateArray';

export const Step1 = ({ onNext, index, totalSteps }: any) => {
  const [newAmenities, setNewAmenities] = useState<Amenities[] | []>();
  const navigate = useNavigate();
  const { data: allKinds } = useAppSelector((store) => store.kinds);
  const { spaceData } = useAppSelector((store) => store.kinds);
  const { data: allCategories } = useAppSelector((store) => store.categories);
  const { data: allAmenities } = useAppSelector((store) => store.amenities);
  // let tempAmenities: Amenities[] = [];
  const [openModal, setOpenModal] = useState<boolean>(false);

  // const {editMode}=useAppSelector(store=>store.properties)

  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };
  const dispatch = useAppDispatch();
  const {
    id,
    title,
    summary,
    category,
    kind,
    space,
    // amenities,
    rules,
    toilets,
    affiliation,
    flood_meter,
    address,
    price,
    currency,
    description,
    intent,
    cycle,
    garages,
    guests,
    beds,
    bedrooms,
    bathrooms,
    floor,
    area,
    virtual_tour_url,
    video_url,
    editMode,
    isLoading,
  } = useAppSelector((store) => store.properties);
  const data = {
    id,
    title,
    summary,
    category,
    kind,
    space,
    amenities: newAmenities,
    rules,
    toilets,
    affiliation,
    flood_meter,
    address,
    price,
    currency,
    description,
    intent,
    cycle,
    garages,
    guests,
    beds,
    bedrooms,
    bathrooms,
    floor,
    area,
    virtual_tour_url,
    video_url,
    slug: title,
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (editMode) {
      try {
        const resp = await dispatch(updateProperty({ id, data }));
        onCloseModal();
        if (resp.meta.requestStatus === 'fulfilled') {
          onNext();
        }
      } catch (error) {}

      return;
    }

    try {
      const resp = await dispatch(
        createProperty({
          title,
          summary,
          category,
          kind,
          space,
          // amenities,
          amenities: newAmenities,
          rules,
          toilets,
          affiliation,
          flood_meter,
          address,
          price,
          currency,
          description,
          intent,
          cycle,
          guests,
          beds,
          bedrooms,
          bathrooms,
          floor,
          area,
          virtual_tour_url,
          video_url,
        })
      );
      if (resp.meta.requestStatus === 'fulfilled') {
        onNext();
      }
    } catch (error) {}
  };
  const handleInput = (e: any) => {
    dispatch(
      handlePropertyChange({
        name: e.target.name,
        value: e.target.value,
      })
    );
  };
  useEffect(() => {
    // dispatch(createProperty({ title: 'test' }));

    dispatch(getAllAmenities(null));
    dispatch(getAllCategories(null));
    dispatch(getAllKinds(null));
    dispatch(getAllSpaces(null));
  }, []);
  return (
    <>
      <form className='tab-content py-6' onSubmit={handleSubmit}>
        <div className='tab-pane fade show active'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-10 col-lg-8 col-xl-6 text-center'>
              <h6 className='mb-4 text-uppercase text-muted'>
                Step {index + 1} of {totalSteps}
              </h6>

              <h1 className='mb-3'>
                {editMode ? 'Update Your Property' : 'Create New Property'}
              </h1>

              <p className='mb-5 text-muted'>
                {editMode
                  ? `Update the latest changes on your property`
                  : `Understanding the type of property you're creating help clients make their best choice.`}
              </p>
            </div>
          </div>

          <div className='form-group'>
            <label className='form-label'>
              What’s the name of your property?
            </label>

            <input
              type='text'
              className='form-control'
              placeholder='Enter property name'
              value={title}
              name='title'
              onChange={handleInput}
              required
            />
          </div>

          <div className='form-group'>
            <label className='form-label'>What kind of property is this?</label>

            <select
              className='form-select mb-3'
              value={kind}
              name='kind'
              onChange={handleInput}
              required
            >
              <option>Select An Option</option>

              {allKinds?.map((kind: any) => {
                return (
                  <option key={kind.id} value={kind.id}>
                    {kind.title}
                  </option>
                );
              })}
            </select>
          </div>
          <div className='form-group'>
            <label className='form-label'>
              What category does the property belong?
            </label>

            <select
              className='form-select mb-3'
              value={category}
              name='category'
              onChange={handleInput}
              required
            >
              <option>Select An Option</option>

              {allCategories?.map((category: any) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.title}
                  </option>
                );
              })}
            </select>
          </div>
          <div className='form-group'>
            <label className='form-label'>Intent</label>

            <select
              className='form-select mb-3'
              value={intent}
              name='intent'
              onChange={handleInput}
              required
            >
              <option>Select An Option</option>
              <option value='shortlet'>Shortlet</option>
              <option value='sales'>Sales</option>
              <option value='long lease'>Long Lease</option>
            </select>
          </div>
          <div className='form-group'>
            <label className='form-label'>Cycle</label>

            <select
              className='form-select mb-3'
              value={cycle}
              name='cycle'
              onChange={handleInput}
              required
            >
              <option>Select An Option</option>
              <option value='yearly'>Yearly</option>
              <option value='monthly'>Monthly</option>
              <option value='daily'>Daily</option>
            </select>
          </div>
          <div className='form-group'>
            <label className='form-label'>Select a space</label>

            <select
              className='form-select mb-3'
              value={space}
              name='space'
              onChange={handleInput}
              required
            >
              <option>Select An Option</option>

              {spaceData?.map((space: any) => {
                return (
                  <option key={space.id} value={space.id}>
                    {space.title}
                  </option>
                );
              })}
            </select>
          </div>
          <div className='form-group'>
            <label className='form-label'>Currency</label>

            <select
              className='form-select mb-3'
              value={currency}
              name='currency'
              onChange={handleInput}
              required
            >
              <option>Select An Option</option>
              <option value='NGN'>NGN</option>
              <option value='USD'>USD</option>
            </select>
          </div>

          <div className='form-group mb-4'>
            <label className='form-label'>Price</label>

            <input
              type='number'
              value={price}
              name='price'
              onChange={handleInput}
              className='form-control'
              required
            />
          </div>
          <div className='form-group mb-4'>
            <label className='form-label'>Maximum No Of Guests</label>

            <input
              type='number'
              value={guests}
              name='guests'
              onChange={handleInput}
              className='form-control'
              // placeholder='Enter a number'
              required
            />
          </div>

          <div className='form-group mb-4'>
            <label className='form-label'>No Of Beds</label>

            <input
              type='number'
              value={beds}
              name='beds'
              onChange={handleInput}
              className='form-control'
              placeholder='Enter a number'
              required
            />
          </div>

          <div className='form-group mb-4'>
            <label className='form-label'>No Of Floors</label>

            <input
              type='number'
              value={floor}
              name='floor'
              onChange={handleInput}
              className='form-control'
              placeholder='Enter a number'
              required
            />
          </div>

          <div className='form-group mb-4'>
            <label className='form-label'>Size</label>

            <input
              type='text'
              value={area}
              name='area'
              onChange={handleInput}
              className='form-control'
              required
            />
          </div>

          <div className='form-group mb-4'>
            <label className='form-label'>No Of Bedrooms</label>

            <input
              type='number'
              value={bedrooms}
              name='bedrooms'
              onChange={handleInput}
              className='form-control'
              required
            />
          </div>

          <div className='form-group mb-4'>
            <label className='form-label'>No Of Bathrooms</label>

            <input
              type='number'
              value={bathrooms}
              name='bathrooms'
              onChange={handleInput}
              className='form-control'
              placeholder='Enter a number'
              required
            />
          </div>
          <div className='form-group mb-4'>
            <label className='form-label'>No Of Toilets</label>

            <input
              type='number'
              value={toilets}
              name='toilets'
              onChange={handleInput}
              className='form-control'
              placeholder='Enter a number'
              required
            />
          </div>
          {/* <div className='form-group mb-4'> */}
          <h4 className='fs-18 fw-bold mb-4'>
            Let your customers know the features of your property
          </h4>
          {allAmenities?.map((f: any) => {
            const { offers, title, id } = f;
            const opt = offers.map((o: any) => {
              return { label: o, value: o };
            });
            return (
              <div className='form-group mb-4' key={id}>
                <label className='fw-bold mb-2 form-label'>{title}</label>
                <Select
                  isMulti
                  name={title}
                  options={opt}
                  className='basic-multi-select'
                  classNamePrefix='form-select'
                  onChange={(e: any) => {
                    const newState = { icon: '', offers: [], title: '' };
                    let m: any = [];
                    for (let i = 0; i < e.length; i++) {
                      m.push(e[i].value);
                    }
                    const newObj = { ...newState, offers: m, title };

                    // updateArray(newObj, tempAmenities);
                    setNewAmenities((prevAmenities: any) =>
                      updateArray(newObj, prevAmenities)
                    );
                  }}
                />
              </div>
            );
          })}
          {/* </div> */}

          <div className='form-group mb-4'>
            <label className='form-label'>No Of Garages</label>

            <input
              type='number'
              value={garages}
              name='garages'
              onChange={handleInput}
              className='form-control'
              placeholder='Enter a number'
              required
            />
          </div>
          <div className='form-group'>
            <label className='form-label'>Describe your property?</label>

            <textarea
              value={description}
              name='description'
              onChange={handleInput}
              rows={6}
              className='form-control'
              placeholder='Enter a short description of this property'
              required
            ></textarea>
          </div>
          <div className='form-group'>
            <label className='form-label'>House Rules</label>

            <textarea
              value={rules}
              name='rules'
              onChange={handleInput}
              rows={6}
              className='form-control'
              placeholder='List the rules of this property'
              required
            ></textarea>
          </div>
          <div className='form-group'>
            <label className='form-label'>Address of your property?</label>

            <textarea
              value={address}
              name='address'
              onChange={handleInput}
              rows={6}
              className='form-control'
              placeholder='Enter a short description of this property'
              required
            ></textarea>
          </div>
          <div className='form-group'>
            <label className='form-label'>
              Give a summary of your property?
            </label>

            <textarea
              value={summary}
              name='summary'
              onChange={handleInput}
              rows={3}
              className='form-control'
              placeholder='Summary of this property'
              required
            ></textarea>
          </div>

          <div className='form-group'>
            <label className='form-label'>Affiliation</label>

            <select
              className='form-select mb-3'
              value={affiliation}
              name='affiliation'
              onChange={handleInput}
              required
            >
              <option value=''>
                What is your affiliation with this property
              </option>
              <option value='Property owner'>Property Owner</option>
              <option value='Direct agent'>Direct Agent</option>
              <option value='Indirect agent'>Indirect Agent</option>
            </select>
          </div>
          <div className='form-group'>
            <label className='form-label'>Flood Risk</label>

            <select
              className='form-select mb-3'
              value={flood_meter}
              name='flood_meter'
              onChange={handleInput}
              required
            >
              <option value=''>Select Flood Risk</option>
              <option value='low'>Low</option>
              <option value='medium'>Medium</option>
              <option value='high'>High</option>
            </select>
          </div>

          <div className='form-group mb-4'>
            <label className='form-label'>Video URL</label>

            <input
              type='text'
              value={video_url}
              name='video_url'
              onChange={handleInput}
              className='form-control'
              placeholder='video link'
            />
          </div>
          <div className='form-group mb-4'>
            <label className='form-label'>Virtual Tour URL</label>

            <input
              type='text'
              value={virtual_tour_url}
              name='virtual_tour_url'
              onChange={handleInput}
              className='form-control'
              placeholder='virtual tour link'
            />
          </div>
          <hr className='my-5' />

          <div className='nav row align-items-center'>
            <div className='col-auto'>
              <button
                className='btn btn-lg btn-white'
                type='reset'
                onClick={() => navigate('/properties')}
              >
                Cancel
              </button>
            </div>

            <div className='col text-center'>
              <h6 className='text-uppercase text-muted mb-0'>
                Step {index + 1} of {totalSteps}
              </h6>
            </div>

            <div className='col-auto'>
              <button
                className='btn btn-lg btn-primary'
                type='button'
                onClick={onOpenModal}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </form>
      <Modal
        open={openModal}
        center
        onClose={onCloseModal}
        // classNames={{ modal: 'w-300 rounded' }}
        classNames={
          {
            // overlayAnimationIn: 'customEnterOverlayAnimation',
            // overlayAnimationOut: 'customLeaveOverlayAnimation',
            // modalAnimationIn: 'customEnterModalAnimation',
            // modalAnimationOut: 'customLeaveModalAnimation',
          }
        }
        animationDuration={800}
      >
        <div className='col px-4'>
          <h2 className='fw-bold fs-20'>
            Are you ready to list this property?
          </h2>
          <p className='light-text'>
            Once you list this property, you can proceed to add the property
            images. Krent will review your listing and your listing would go
            live once it is approved.
          </p>

          <div className='row'>
            <div className='col-6'>
              <button className='btn btn-primary' onClick={onCloseModal}>
                Do This Later
              </button>
            </div>
            <div className='col-6'>
              <button className='btn btn-primary' onClick={handleSubmit}>
                {isLoading ? 'loading' : 'List Property'}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const Step2 = ({
  // onNext,
  // onPrev,
  index,
  totalSteps,
}: any) => {
  const [form, setForm]: any = useState({});
  const [coverImage1, setCoverImage1]: any = useState([]);
  const [otherImages1, setOtherImages1]: any = useState([]);
  const [floorPlans1, setFloorPlans1]: any = useState([]);

  const { newPropertyId, editMode } = useAppSelector(
    (store) => store.properties
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id }: any = useParams()!;

  const handleChange = (e: any) => {
    const value = e.target.files;
    const name = e.target.name;

    if (name === 'coverImage') {
      setCoverImage1(value);
    }

    if (name === 'floorPlans') {
      setFloorPlans1(value);
    }
    if (name === 'otherImages') {
      setOtherImages1(value);
    }
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const formData = new FormData();
    // if (form.coverImage) {
    //   formData.append('coverImage', form.coverImage);
    // }
    // if (form.floorPlans) {
    //   formData.append('floorPlans', form.floorPlans);
    // }
    // if (form.otherImages) {
    //   formData.append('otherImages', form.otherImages);
    // }

    for (let i = 0; i < otherImages1.length; i++) {
      formData.append('otherImages', otherImages1[i]);
    }
    for (let i = 0; i < coverImage1.length; i++) {
      formData.append('coverImage', coverImage1[i]);
    }
    for (let i = 0; i < floorPlans1.length; i++) {
      formData.append('floorPlans', floorPlans1[i]);
    }

    // onNext();
    if (editMode) {
      dispatch(addPropertyImage({ id, data: formData }));
      setTimeout(() => {
        navigate(`/properties/${id}`);
      }, 4000);
      return;
    }

    dispatch(addPropertyImage({ id: newPropertyId!, data: formData }));
    setTimeout(() => {
      navigate(`/properties/${newPropertyId}`);
    }, 4000);
  };

  return (
    <>
      <form className='tab-content py-6' onSubmit={handleSubmit}>
        <div className='tab-pane fade show active'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-10 col-lg-8 col-xl-6 text-center'>
              <h6 className='mb-4 text-uppercase text-muted'>
                Step {index + 1} of {totalSteps}
              </h6>

              <h1 className='mb-3'>Create New Property</h1>

              <p className='mb-5 text-muted'>
                Understanding the type of team you're creating help us to ask
                all the right questions.
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 col-md-6 mb-4 mb-md-0'>
              <div className='form-group mb-4'>
                <label htmlFor='coverImage'>Cover Image</label>
                <input
                  type='file'
                  name='coverImage'
                  id='coverImage'
                  onChange={handleChange}
                  className='form-control'
                />
              </div>
              <div className='form-group mb-4'>
                <label htmlFor='floorPlans'>Floor plan</label>
                <input
                  type='file'
                  multiple
                  name='floorPlans'
                  id='floorPlans'
                  onChange={handleChange}
                  className='form-control'
                />
              </div>
              <div className='form-group mb-4'>
                <label htmlFor='otherImages'>Other Images</label>
                <input
                  type='file'
                  multiple
                  name='otherImages'
                  id='otherImages'
                  onChange={handleChange}
                  className='form-control'
                />
              </div>
            </div>
          </div>

          <hr className='my-5' />

          <div className='nav row align-items-center'>
            {/* <div className='col-auto'>
              <button className='btn btn-lg btn-white' onClick={onPrev}>
                Back
              </button>
            </div> */}

            <div className='col text-center'>
              <h6 className='text-uppercase text-muted mb-0'>
                Step {index + 1} of {totalSteps}
              </h6>
            </div>

            <div className='col-auto'>
              <button className='btn btn-lg btn-primary' type='submit'>
                Continue
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

// export const Step3 = ({ onNext, onPrev, index, totalSteps }: any) => {
//   const handleSubmit = (e: any) => {
//     e.preventDefault();
//     onNext();
//   };

//   return (
//     <>
//       <form className='tab-content py-6' onSubmit={handleSubmit}>
//         <div className='tab-pane fade show active'>
//           <div className='row justify-content-center'>
//             <div className='col-12 col-md-10 col-lg-8 col-xl-6 text-center'>
//               <h6 className='mb-4 text-uppercase text-muted'>
//                 Step {index + 1} of {totalSteps}
//               </h6>

//               <h1 className='mb-3'>Create New Property</h1>

//               <p className='mb-5 text-muted'>
//                 Understanding the type of team you're creating help us to ask
//                 all the right questions.
//               </p>
//             </div>
//           </div>

//           <div className='row'>
//             <div className='col-12 col-md-6 mb-4 mb-md-0'>
//               <h3>Congratulations You Have successfully added your property</h3>
//             </div>
//           </div>

//           <hr className='my-5' />

//           <div className='nav row align-items-center'>
//             <div className='col-auto'>
//               <button className='btn btn-lg btn-white' onClick={onPrev}>
//                 Back
//               </button>
//             </div>

//             <div className='col text-center'>
//               <h6 className='text-uppercase text-muted mb-0'>
//                 Step {index + 1} of {totalSteps}
//               </h6>
//             </div>

//             <div className='col-auto'>
//               <button className='btn btn-lg btn-primary' type='submit'>
//                 Continue
//               </button>
//             </div>
//           </div>
//         </div>
//       </form>
//     </>
//   );
// };
