import { fetchAllIndividualTransactions } from 'features/transactions/TransactionsSlice';
// import { ChangePassword } from 'features/users/LoginUser.interface';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  forgotPassword,
  getUser,
  // logoutUser,
} from '../features/users/UserSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
// import customFetch from '../utils/axios';
import { userImgBaseUrl } from '@utils/urls';
import moment from 'moment';
import img from '/images/user.jpeg';

import { DashboardLayout } from '@layouts/DashboardLayout';

import {
  getTransactionsByWalletId,
  getWalletById,
} from 'features/wallet/walletSlice';
import { handleChange, next, paginate, prev } from '@utils/pagination';
import { Table } from 'components';

const Profile = () => {
  const [page, setPage] = useState(0);

  const { isLoading, singleUser } = useAppSelector((store) => store.user);
  const { individualTransactions } = useAppSelector(
    (store) => store.transactions
  );

  const {
    walletInfo,
    walletTransaction,
    isLoading: walletLoading,
  } = useAppSelector((store) => store.wallet);

  const { id }: any = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const handleLogout = () => {
  // 	dispatch(logoutUser());
  // 	navigate('/sign-in');
  // };

  const handleForgotPassword = (param: any) => {
    dispatch(forgotPassword(param));
  };

  // const handleGetWalletById = () => {
  //   dispatch(getWalletById(singleUser.wallet));
  // };

  const handleGetTransactionsByWalletId = () => {
    dispatch(getTransactionsByWalletId(singleUser.wallet));
  };

  useEffect(() => {
    dispatch(fetchAllIndividualTransactions(id));
    dispatch(getUser(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    if (singleUser?.wallet) {
      dispatch(getWalletById(singleUser.wallet));
      // dispatch(getTransactionsByWalletId(singleUser.wallet));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleUser]);

  if (isLoading || individualTransactions === null || singleUser === null) {
    return <h1>Loading....</h1>;
  }

  const pageData = paginate(individualTransactions);

  const {
    aboutMe,
    canTransact,
    createdAt,
    // disabled,
    email,
    // favorite_listings,
    // hasPIN,
    id: ID,
    inspections,
    language,
    name,
    passwordChangedAt,
    phone,
    phoneVerifiedAt,
    photo,
    role,
    timezone,
    // updatedAt,
    verified,
    wallet,
  } = singleUser;

  // const { balance, currency, credits, debits, paymentMethod } = walletInfo;

  return (
    <DashboardLayout>
      <div className='container-fluid'>
        <button
          className='btn btn-dark mt-4'
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </button>
      </div>
      <div className='container mt-5 px-5'>
        <div className='row'>
          {singleUser.wallet && (
            <div className='col-12 mb-5'>
              <div className='row'>
                <div className='col-6 col-md-3'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='row align-items-center'>
                        <div className='col'>
                          <h6 className='text-uppercase text-muted mb-2'>
                            Book Balance
                          </h6>

                          <span className='h2 mb-0'>
                            <span className='text-muted'>
                              {walletInfo?.currency}{' '}
                            </span>
                            {walletInfo?.balance['book'].toLocaleString()}
                          </span>

                          {/* <span className='badge bg-success-soft mt-n1'>
													+3.5%
												</span> */}
                        </div>
                        {/* <div className='col-auto'>
                          <span className='h2 fe fe-dollar-sign text-muted mb-0'></span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-3'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='row align-items-center'>
                        <div className='col'>
                          <h6 className='text-uppercase text-muted mb-2'>
                            Available Balance
                          </h6>

                          <span className='h2 mb-0'>
                            <span className='text-muted'>
                              {walletInfo?.currency}
                            </span>
                            {walletInfo?.balance['available'].toLocaleString()}
                          </span>

                          {/* <span className='badge bg-success-soft mt-n1'>
													+3.5%
												</span> */}
                        </div>
                        {/* <div className='col-auto'>
                          <span className='h2 fe fe-dollar-sign text-muted mb-0'></span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-3'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='row align-items-center'>
                        <div className='col'>
                          <h6 className='text-uppercase text-muted mb-2'>
                            Credits
                          </h6>

                          <span className='h2 mb-0'>
                            <span className='text-muted'>
                              {walletInfo?.currency}{' '}
                            </span>
                            {walletInfo?.credits.toLocaleString()}
                          </span>

                          {/* <span className='badge bg-success-soft mt-n1'>
													+3.5%
												</span> */}
                        </div>
                        {/* <div className='col-auto'>
                          <span className='h2 fe fe-dollar-sign text-muted mb-0'></span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-3'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='row align-items-center'>
                        <div className='col'>
                          <h6 className='text-uppercase text-muted mb-2'>
                            Debits
                          </h6>

                          <span className='h2 mb-0'>
                            <span className='text-muted'>
                              {walletInfo?.currency}{' '}
                            </span>
                            {walletInfo?.debits.toLocaleString()}
                          </span>
                        </div>
                        {/* <div className='col-auto'>
                          <span className='h2 fe fe-dollar-sign text-muted mb-0'></span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='col-12 col-md-4'>
            <div className='card'>
              <div className='card-body'>
                <a
                  href='profile-posts.html'
                  className='avatar avatar-xl card-avatar card-avatar-top'
                >
                  <img
                    // src={img}
                    src={photo === 'default.jpg' ? img : userImgBaseUrl + photo}
                    // src='../assets/img/avatars/profiles/avatar-1.jpg'
                    className='avatar-img rounded-circle border border-4 border-card'
                    alt='img'
                  />
                </a>

                <h2 className='card-title text-center'>
                  <a href='profile-posts.html'>{name}</a>
                </h2>

                <p className='small text-center text-muted mb-3'>{aboutMe}</p>
                <p className='small text-center text-muted mb-3'>{ID}</p>
                <p className='small text-center text-muted mb-3'>{email}</p>

                <p className='text-center mb-4'>
                  <span className='badge bg-secondary-soft'>{role}</span>
                  <button
                    className='btn badge bg-danger ms-2'
                    onClick={() => handleForgotPassword(email)}
                  >
                    Reset Password
                  </button>
                </p>

                <div className='row g-0 border-top border-bottom'>
                  <div className='col-6 py-4 text-center'>
                    <h6 className='text-uppercase text-muted'>Inspections</h6>

                    <h2 className='mb-0'>{inspections}</h2>
                  </div>
                  <div className='col-6 py-4 text-center border-start'>
                    {/* <h6 className='text-uppercase text-muted'>Wallet</h6> */}

                    {/* <h2 className='mb-0'>{timezone}</h2> */}

                    <a
                      href='#!'
                      data-bs-toggle='modal'
                      data-bs-target='#wallet'
                    >
                      <span
                        className='item-intent btn btn-success'
                        onClick={handleGetTransactionsByWalletId}
                      >
                        Wallet
                      </span>
                    </a>

                    {/* <!-- Modal --> */}
                    <div
                      className='modal fade bd-example-modal-lg'
                      id='wallet'
                      tabIndex={-1}
                      role='document'
                      aria-hidden='true'
                    >
                      <div className='modal-dialog modal-lg' role='document'>
                        <div className='modal-content'>
                          <div className='modal-header'>
                            <h3 className='modal-title' id='reviewModalLabel'>
                              Wallet Transactions
                            </h3>
                          </div>
                          <div className='modal-body text-wrap'>
                            {walletLoading || walletTransaction === null ? (
                              'Loading...'
                            ) : walletTransaction.length === 0 ? (
                              <h3>No Wallet Transactions Found</h3>
                            ) : (
                              <Table
                                data={walletTransaction}
                                th={[
                                  'id',
                                  'amount',
                                  'type',
                                  'destination',
                                  'status',
                                  'processed by',
                                  'initiated at',
                                  'transaction id',
                                ]}
                                walletTransactions
                              />
                            )}
                          </div>
                          <div className='modal-footer d-flex  justify-content-between'>
                            <div>
                              <button
                                type='button'
                                className='btn btn-secondary'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='list-group list-group-flush mb-4'>
                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Joined</small>
                      </div>
                      <div className='col-auto'>
                        <time
                          className='small text-muted'
                          dateTime='1988-10-24'
                        >
                          {moment(createdAt).format('DD/MM/YY')}
                        </time>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Phone</small>
                      </div>
                      <div className='col-auto'>
                        <small className='text-muted'>+{phone}</small>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Last Password Change</small>
                      </div>
                      <div className='col-auto'>
                        <small className='small text-muted'>
                          {moment(passwordChangedAt).format('DD/MM/YY')}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Phone Verification Date</small>
                      </div>
                      <div className='col-auto'>
                        <small className='small text-muted'>
                          {moment(phoneVerifiedAt).format('DD/MM/YY')}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Language</small>
                      </div>
                      <div className='col-auto'>
                        <small className='small text-muted'>{language}</small>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Wallet</small>
                      </div>
                      <div className='col-auto'>
                        <small className='small text-muted'>{wallet}</small>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Timezone</small>
                      </div>
                      <div className='col-auto'>
                        <small className='small text-muted'>{timezone}</small>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row align-items-center justify-content-between'>
                  <div className='col-auto'>
                    {verified ? (
                      <small>
                        <span className='text-success'>●</span> Verified
                      </small>
                    ) : (
                      <small>
                        <span className='text-danger'>●</span> Unverified
                      </small>
                    )}
                  </div>
                  <div className='col-auto'>
                    {canTransact ? (
                      <small>
                        <span className='text-success'>●</span> Transactions
                        Enabled
                      </small>
                    ) : (
                      <small>
                        <span className='text-danger'>●</span> Transactions
                        Disabled
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-8'>
            <h3 style={{ marginTop: '-30px' }} className='text-center'>
              TRANSACTIONS
            </h3>
            <div className='card'>
              {individualTransactions && individualTransactions.length > 1 ? (
                <div className='table-responsive'>
                  <table className='table table-sm table-nowrap card-table'>
                    <thead>
                      <tr>
                        <th>Property</th>
                        <th>Intent</th>
                        <th>Total</th>
                        <th>Date</th>
                        <th>Status</th>
                        {/* <th colSpan={2}>Payment method</th> */}
                      </tr>
                    </thead>
                    <tbody className='list'>
                      {pageData[page].map((transaction: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td className='orders-order'>
                              <Link to={`/transactions/${transaction.id}`}>
                                {transaction.listing?.title.length > 0 &&
                                  transaction.listing?.title.slice(0, 20) +
                                    '...'}
                              </Link>
                            </td>
                            <td className='orders-order'>
                              {transaction.listing?.intent}
                            </td>

                            <td className='orders-total'>
                              <span className='text-dark fw-bold'>
                                {transaction.listing?.currency}
                              </span>
                              {transaction.bill?.total.toLocaleString()}
                            </td>
                            <td className='orders-date'>
                              <time dateTime='2018-07-30'>
                                {moment(transaction.updatedAt).format(
                                  'DD/MM/YY'
                                )}
                              </time>
                            </td>
                            <td className='orders-status'>
                              {transaction.status === 'customer-accepted' ? (
                                <div className='badge bg-primary-soft'>
                                  Accepted
                                </div>
                              ) : transaction.status === 'completed' ? (
                                <div className='badge bg-success-soft'>
                                  Completed
                                </div>
                              ) : transaction.status === 'in-progress' ? (
                                <div className='badge bg-warning-soft'>
                                  In Progress
                                </div>
                              ) : (
                                <div className='badge bg-danger-soft'>
                                  {transaction.status}
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className='row g-0'>
                    {/* <!-- Pagination (prev) --> */}
                    <div className='col list-pagination-prev pagination pagination-tabs justify-content-start'>
                      {/* <li className='page-item' onClick={prev}> */}
                      <span
                        className='page-item '
                        onClick={() => {
                          prev(setPage, pageData);
                        }}
                      >
                        <button className='page-link'>
                          <i className='fe fe-arrow-left ms-1'></i>
                          Previous
                        </button>
                      </span>
                      {/* </li> */}
                    </div>

                    {/* <!-- Pagination --> */}
                    <ul className='col list-pagination pagination pagination-tabs justify-content-center'>
                      {pageData.map((_, index) => {
                        return (
                          <li
                            className={
                              page === index ? 'page-item active' : 'page-item'
                            }
                            key={index}
                          >
                            <button
                              className='page-link'
                              onClick={() => {
                                handleChange(setPage, index);
                              }}
                            >
                              {index + 1}
                            </button>
                          </li>
                        );
                      })}
                    </ul>

                    {/* <!-- Pagination (next) --> */}
                    <div className='col list-pagination-next pagination pagination-tabs justify-content-end'>
                      {/* <li className='page-item'>
                        <a className='page-link' href='#'>
                          Next <i className='fe fe-arrow-right ms-1'></i>
                        </a>
                      </li> */}
                      <span className='page-item'>
                        <button
                          className='page-link'
                          onClick={() => {
                            next(setPage, pageData);
                          }}
                        >
                          Next <i className='fe fe-arrow-right ms-1'></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='card-body text-center'>
                  <h4>No Transactions Available</h4>
                </div>
              )}
            </div>
          </div>
          {singleUser.wallet && (
            <div className='col-12'>
              <div className='card'>
                <div className='card-header'>
                  <div className='row align-items-center'>
                    <div className='col'>
                      <h4 className='card-header-title'>Payment methods</h4>
                    </div>
                    {/* <div className='col-auto'>
										<a className='btn btn-sm btn-primary' href='#!'>
											Add method
										</a>
									</div> */}
                  </div>
                </div>

                <div className='card-body'>
                  <div className='list-group list-group-flush my-n3'>
                    {singleUser.wallet && (
                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col ms-n2'>
                            <h4 className='mb-1'>
                              {walletInfo?.paymentMethod?.accountName}
                            </h4>

                            <small className='text-muted'>
                              <h4 className='mb-1 mt-3'>
                                {walletInfo?.paymentMethod?.accountNumber}
                              </h4>
                            </small>

                            <small className='text-muted'>
                              {walletInfo?.paymentMethod?.bankName}
                            </small>
                          </div>
                          <div className='col-auto me-n3'>
                            <span className='badge bg-light p-3'>
                              {walletInfo?.paymentMethod?.accountType ===
                              'Current'
                                ? 'Current Account'
                                : 'Savings Account'}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* {singleUser?.role === 'landlord' && (
          <>
            <div>
              <button
                onClick={() => {
                  if (singleUser.wallet) {
                    handleGetWalletById();
                  } else {
                    console.log('User does not have wallet ID');
                  }
                }}
                className='btn  w-20 btn-danger mb-12'
              >
                fetch Wallet by id
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  if (singleUser.wallet) {
                    handleGetTransactionsByWalletId();
                  } else {
                    console.log('User does not have wallet ID');
                  }
                }}
                className='btn  w-20 btn-danger mb-12'
              >
                fetch trans by Wallet ID
              </button>
            </div>
          </>
        )} */}
      </div>
    </DashboardLayout>
  );
};

export default Profile;
