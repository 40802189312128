import customFetch, { checkUnauthorizedResponse } from '../../utils/axios';
import {
  AddPropertyImage,
  // CreateProperty,
  UpdateProperty,
} from './EditProperty.interface';
import { fetchAllProperties, getProperty } from './PropertiesSlice';

// fetch all properties
export const getAllPropertiesThunk = async (
  params: any | undefined,
  thunkApi: any
): Promise<any> => {
  params = params || {};
  let query = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');

  try {
    const resp = await customFetch.get(`/properties?${query}`);

    return resp.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};

// fetch single property

export const singlePropertyThunk = async (
  id: any,
  thunkApi: any
): Promise<unknown> => {
  try {
    const resp = await customFetch.get(`/properties/${id}`);
    if (resp) {
      return resp.data.data;
    }
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data.errors[0].message);
  }
};

// add propertry image

export const addPropertyImageThunk = async (
  { id, data }: AddPropertyImage,
  thunkApi: any
): Promise<unknown> => {
  try {
    const resp = await customFetch.put(`/properties/${id}/add-images`, data);

    return resp.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data.errors[0].message);
  }
};

// CREATE PROPERTY
export const createPropertyThunk = async (
  prop: any,
  thunkApi: any
): Promise<unknown> => {
  // const createPropertyData = thunkApi.getState();
  // const {
  //   title,
  //   summary,
  //   category,
  //   kind,
  //   space,
  //   amenities,
  //   address,
  //   price,
  //   currency,
  //   description,
  //   intent,
  //   cycle,
  //   guests,
  //   beds,
  //   bedrooms,
  //   bathrooms,
  //   floor,
  //   area,
  //   virtual_tour_url,
  //   video_url,
  // } = createPropertyData;
  // const data = {
  //   title,
  //   summary,
  //   category,
  //   kind,
  //   space,
  //   amenities,
  //   address,
  //   price,
  //   currency,
  //   description,
  //   intent,
  //   cycle,
  //   guests,
  //   beds,
  //   bedrooms,
  //   bathrooms,
  //   floor,
  //   area,
  //   virtual_tour_url,
  //   video_url,
  // };

  try {
    const resp = await customFetch.post('/properties/', prop);
    return resp.data.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data.errors[0].message);
  }
};

// Update Property
export const updatePropertyThunk = async (
  { id, data }: UpdateProperty,
  thunkApi: any
): Promise<unknown> => {
  try {
    const resp = await customFetch.put(`/properties/${id}`, data);

    return resp.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data.errors[0].message);
  }
};

// Delete Property

export const deletePropertyThunk = async (
  id: string,
  thunkApi: any
): Promise<unknown> => {
  try {
    const statePage: any = thunkApi.getState();
    const { page } = statePage.properties;

    const resp = await customFetch.delete(`/properties/${id}`);
    thunkApi.dispatch(fetchAllProperties({ page }));
    return resp.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data.errors[0].message);
  }
};

// Toggle Publish Property

export const togglePublishThunk = async (
  data: { id: string; payload: any },
  thunkApi: any
): Promise<unknown> => {
  try {
    // const statePage: any = thunkApi.getState();
    // const { page } = statePage.properties;

    const resp = await customFetch.put(`/properties/${data.id}`, data.payload);
    thunkApi.dispatch(getProperty(data.id));
    return resp.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data.errors[0].message);
  }
};

// Get Properties pending approval
export const getPendingApprovalThunk = async (
  params: any,
  thunkApi: any
): Promise<any> => {
  let query = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  try {
    const resp = await customFetch.get(`/properties?${query}`);

    return resp.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};
