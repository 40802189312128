import { useEffect } from 'react';
import { DashboardLayout } from '../components/layouts/DashboardLayout';
import { PageHeader, Pagination, Table } from '../components';

import { changePage, getAllUser } from '../features/users/UserSlice';
import { useAppDispatch, useAppSelector } from '../hooks';

const Customers = () => {
  const { searchKey, data, page, numOfPages } = useAppSelector(
    (store) => store.user
  );
  const url = { role: 'user', page: page };

  const dispatch = useAppDispatch();

  useEffect(() => {
    // if (searchKey) {
    //   dispatch(searchUser(searchKey));
    // } else {
    //   dispatch(getAllUser(url));
    // }
    if (!searchKey) {
      dispatch(getAllUser(url));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchKey]);

  return (
    <DashboardLayout>
      <div className='container-fluid'></div>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <PageHeader headerLink={false} title='Customers' />

            <Table
              data={data}
              th={[
                'name',
                'email',
                'phone',
                'account Status',
                'verification Status',
                'date Joined',
              ]}
              user
            />

            {numOfPages > 1 && (
              <Pagination
                page={page}
                numOfPages={numOfPages}
                changePage={changePage}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
export default Customers;
