import { useEffect, useState } from 'react';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';

import {
  getAllAmenities,
  CreateAmenities,
  deleteAmenities,
  // handleAmenitiesChange,
} from 'features/amenities/amenitiesSlice';
import { DashboardLayout } from '@layouts/DashboardLayout';
import { PageHeader } from 'components';

const Amenities = () => {
  const [title, setTitle] = useState<string | ''>('');
  const [offer, setOffer] = useState<string | ''>('');
  const [offers, setOffers] = useState<string[] | any>([]);

  const dispatch = useAppDispatch();
  const amenitiesState = useAppSelector((state) => state.amenities);

  // let offers:string[] =[]

  // const handleOffer = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value: string = e.target.value;
  //   offers=[...offers,value]
  // };

  const handleOffers = () => {
    setOffers((prev: any) => [...prev, offer]);
    setOffer('');
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(CreateAmenities({ title, icons: '', offers }));
    setTitle('');
    setOffers([]);
  };

  const handleDelete = (id: string) => {
    dispatch(deleteAmenities(id));
  };

  useEffect(() => {
    dispatch(getAllAmenities({}));
  }, [dispatch]);

  return (
    <DashboardLayout>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <PageHeader headerLink={false} title='Amenities' />
            <div className='container mt-4'>
              <h1>Amenities</h1>
              <form onSubmit={handleSubmit}>
                <div className='mb-3'>
                  <label htmlFor='title' className='form-label'>
                    Title
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='title'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                <div className='mb-3'>
                  <label htmlFor='offers' className='form-label'>
                    Offers
                  </label>
                  <div className='input-group'>
                    <input
                      type='text'
                      className='form-control'
                      id='offers'
                      value={offer}
                      onChange={(e) => setOffer(e.target.value)}
                    />
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={handleOffers}
                    >
                      Add
                    </button>
                  </div>
                  <div className='mt-2'>
                    {offers.map((offer: string, index: number) => (
                      <span key={index} className='badge bg-primary me-2'>
                        {offer}
                      </span>
                    ))}
                  </div>
                </div>
                <button type='submit' className='btn btn-primary'>
                  Add Amenities
                </button>
              </form>

              <div className='mt-4'>
                <h2>All Amenities</h2>
                <div className='row row-cols-1 row-cols-md-3 row-cols-lg-4 g-4'>
                  {amenitiesState.data ? (
                    amenitiesState.data.map((amenity: any) => (
                      <div key={amenity.id} className='col'>
                        <div className='card'>
                          <div className='card-body'>
                            <h5 className='card-title'>{amenity.title}</h5>
                            <p className='card-text'>{amenity.icon}</p>
                            <ul className='list-group'>
                              {amenity.offers.map(
                                (offer: [], index: number) => (
                                  <li key={index} className='list-group-item'>
                                    {offer}
                                  </li>
                                )
                              )}
                            </ul>
                            <button
                              className='btn btn-danger mt-2'
                              onClick={() => handleDelete(amenity.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>Loading...</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Amenities;
