import { useEffect, useState } from 'react';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { DashboardLayout } from '@layouts/DashboardLayout';
import { Button, Card, Form } from 'react-bootstrap';
import {
  CreateKind,
  CreateSpace,
  deleteKind,
  deleteSpace,
  getAllKinds,
  getAllSpaces,
  // handleKindsChange,
} from 'features/kind/kindSlice';
import { PageHeader } from 'components';

const Kind = () => {
  const [kindTitle, setKindTitle] = useState<string | ''>('');
  const [spaceTitle, setSpaceTitle] = useState<string | ''>('');

  const dispatch = useAppDispatch();
  const kindState = useAppSelector((state) => state.kinds);

  useEffect(() => {
    dispatch(getAllKinds({}));
    dispatch(getAllSpaces({}));
  }, [dispatch]);

  const handleChange = (e: any) => {
    const name = e.target.name;
    if (name === 'kindTitle') {
      setKindTitle(e.target.value);
    }
    if (name === 'spaceTitle') {
      setSpaceTitle(e.target.value);
    }
  };

  const handleCreateKind = () => {
    dispatch(CreateKind({ title: kindTitle }));
    setKindTitle('');
  };

  const handleCreateSpace = () => {
    dispatch(CreateSpace({ title: spaceTitle }));
    setSpaceTitle('');
  };

  const handleDeleteKind = (id: string) => {
    dispatch(deleteKind(id));
  };
  const handleDeleteSpace = (id: string) => {
    dispatch(deleteSpace(id));
  };
  return (
    <DashboardLayout>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <PageHeader headerLink={false} title='Kinds' />
            <div className='container mt-4'>
              {/* Kind Form */}
              <Form>
                <Form.Group controlId='formKindTitle'>
                  <Form.Label>Kind Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter kind title'
                    name='kindTitle'
                    value={kindTitle}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Button
                  variant='primary'
                  onClick={handleCreateKind}
                  className='mt-3'
                >
                  Create Kind
                </Button>
              </Form>

              <Form className='mt-4'>
                <Form.Group controlId='formSpaceTitle'>
                  <Form.Label>Space Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter space title'
                    name='spaceTitle'
                    value={spaceTitle}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Button
                  variant='primary'
                  onClick={handleCreateSpace}
                  className='mt-3'
                >
                  Create Space
                </Button>
              </Form>
              <h3 className='mt-4'>Kinds</h3>
              <div className='row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-4'>
                {kindState.data ? (
                  kindState.data.map((kind: any) => (
                    <div key={kind.id} className='col'>
                      <Card style={{ position: 'relative' }}>
                        <Card.Body>
                          <Card.Title>{kind.title}</Card.Title>
                          <div
                            className='dropdown'
                            style={{
                              position: 'absolute',
                              top: '5px',
                              right: '7px',
                              cursor: 'pointer',
                              color: 'red',
                            }}
                          >
                            <button
                              className=' dropdown-ellipses dropdown-toggle btn btn-link'
                              type='button'
                              id='dropdownMenuButton1'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              <i className='fe fe-more-vertical '></i>
                            </button>
                            <div className='dropdown-menu dropdown-menu-end'>
                              <button
                                className='dropdown-item btn btn-link'
                                onClick={() => handleDeleteKind(kind.id)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))
                ) : (
                  <div>Loading...</div>
                )}
              </div>

              {/* Space Form */}

              <h3>Space</h3>
              <div className='row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-4'>
                {kindState.spaceData ? (
                  kindState.spaceData.map((space: any) => (
                    <div key={space.id} className='col'>
                      <Card style={{ position: 'relative' }}>
                        <Card.Body>
                          <Card.Title>{space.title}</Card.Title>

                          <div
                            className='dropdown'
                            style={{
                              position: 'absolute',
                              top: '5px',
                              right: '7px',
                              cursor: 'pointer',
                              color: 'red',
                            }}
                          >
                            <button
                              className=' dropdown-ellipses dropdown-toggle btn btn-link'
                              type='button'
                              id='dropdownMenuButton1'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              <i className='fe fe-more-vertical '></i>
                            </button>
                            <div className='dropdown-menu dropdown-menu-end'>
                              <button
                                className='dropdown-item btn btn-link'
                                onClick={() => handleDeleteSpace(space.id)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
export default Kind;
