import { useAppDispatch } from '../hooks/useAppDispatch';
import { fetchSingleReview } from 'features/reviews/ReviewSlice';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../hooks/useAppSelector';

const SingleReview = () => {
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const { isLoading, singleReview } = useAppSelector((store) => store.reviews);

  useEffect(() => {
    dispatch(fetchSingleReview(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading || singleReview === null) {
    return <h1>Loading...</h1>;
  }
  return <h1>SingleReview</h1>;
};

export default SingleReview;
