import { useAppDispatch } from '../hooks';
import { Link } from 'react-router-dom';
import img from '../images/download.png';
import {
  deleteProperty,
  getProperty,
} from 'features/properties/PropertiesSlice';
import moment from 'moment';
const PropertyTableData = ({ data }: any) => {
  const dispatch = useAppDispatch();

  return (
    <tbody className='list fs-base'>
      {data?.map((info: any) => {
        const {
          slug,
          intent,
          user,
          createdAt,
          location,
          price,
          cycle,
          id,
          // category,
          published,
          // otherImages,
          coverImage,
        } = info;
        return (
          <tr key={id}>
            <td>
              <div className='form-check'>
                <input
                  className='form-check-input list-checkbox'
                  id='listCheckboxOne'
                  type='checkbox'
                />
                <label
                  className='form-check-label'
                  htmlFor='listCheckboxOne'
                ></label>
              </div>
            </td>
            <td>
              <div className='avatar avatar-md align-middle me-2'>
                <img
                  className='avatar-img rounded-circle'
                  src={coverImage ? coverImage : img}
                  alt=''
                />
              </div>
              <Link to={`/properties/${id}`}>{slug}</Link>
            </td>
            <td>
              <span className='item-intent'>{intent}</span>
            </td>
            <td>
              <span
                className={`badge ${
                  published === true ? 'bg-success-soft' : 'bg-danger-soft'
                }`}
              >
                {published === true ? 'published' : 'unpublished'}
              </span>
            </td>
            <td>
              <span className='item-location'>{location.city}</span>
            </td>
            <td>
              <span className='item-price'>{price}</span>
            </td>
            <td>
              <span className='item-cycle'>{cycle}</span>
            </td>
            <td>
              <span className='item-user'>{`${user?.firstname} ${user?.lastname}`}</span>
            </td>
            <td>
              <span className='item-createdAt text-reset'>
                {moment(createdAt).format('MMM Do YY')}
              </span>
            </td>

            <td className='text-end'>
              <div className='dropdown'>
                {/* <a
                                  className='dropdown-ellipses dropdown-toggle'
                                  href='#!'
                                  role='button'
                                  data-bs-toggle='dropdown'
                                  aria-haspopup='true'
                                  aria-expanded='false'
                                >
                                  <i className='fe fe-more-vertical'></i>
                                </a> */}

                <button
                  className=' dropdown-ellipses dropdown-toggle btn btn-link'
                  id='dropdownMenuButton1'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                  type='button'
                >
                  <i className='fe fe-more-vertical'></i>
                </button>
                <div className='dropdown-menu dropdown-menu-end'>
                  <Link
                    to={`/edit-property/${id}`}
                    className='dropdown-item'
                    onClick={() => dispatch(getProperty(id))}
                  >
                    Edit
                  </Link>
                  {/* <Link to={`/properties/${id}`} className='dropdown-item'>
                    Edit
                  </Link> */}
                  <button
                    className='dropdown-item btn btn-link'
                    onClick={() => {
                      dispatch(deleteProperty(id));
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default PropertyTableData;
