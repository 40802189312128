import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import customFetch, { checkUnauthorizedResponse } from '@utils/axios';
import { toast } from 'react-toastify';

interface AsyncState {
  isLoading: boolean;
}

interface AuthState extends AsyncState {
  success?: boolean;
  data?: any | null;
  total?: number;
  page?: number;
  page_count?: number;
  pagination?: any;
  title?: any | null;
  description?: any | null;
}

const initialDataState = {
  title: '',
  description: '',
};
const initialState: AuthState = {
  data: null,
  isLoading: false,
  success: false,
  page_count: 1,
  page: 1,
  total: 1,
  ...initialDataState,
};

// Get All Categories
export const getAllCategories = createAsyncThunk(
  'categories/allCategories',
  async (thunkApi: any): Promise<unknown> => {
    try {
      const resp = await customFetch.get('/categories');

      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);
// Delete Category
export const deleteCategory = createAsyncThunk(
  'categories/deleteCategory',
  async (id: string, thunkApi: any): Promise<unknown> => {
    try {
      const resp = await customFetch.delete(`/categories/${id}`);
      thunkApi.dispatch(getAllCategories({}));

      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);
// Edit Category
export const editCategory = createAsyncThunk(
  'categories/editCategory',
  async (id: string, thunkApi: any): Promise<unknown> => {
    try {
      const resp = await customFetch.put(`/categories/${id}`);
      thunkApi.dispatch(getAllCategories({}));

      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);
// Create Categories
export const CreateCategories = createAsyncThunk(
  'categories/createCategories',
  async (
    data: { title: string; description: string },
    thunkApi: any
  ): Promise<unknown> => {
    // const data = thunkApi.getState();
    // const { title, description } = data.categories;

    try {
      const resp = await customFetch.post('/categories', data);
      // console.log(payload);
      thunkApi.dispatch(getAllCategories({}));
      return resp.data;
    } catch (error) {
      checkUnauthorizedResponse(error, thunkApi);
    }
  }
);

const CategoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    handleCategoryChange: (state: any, { payload }: any) => {
      const { name, value } = payload;
      state[name] = value;
    },
  },
  extraReducers: (builder) => {
    // Get All Categories
    builder.addCase(getAllCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllCategories.fulfilled,
      (state: any, { payload }: any) => {
        const { page_count, data, pagination, success, total } = payload;

        state.data = data;
        state.page_count = page_count;
        state.pagination = pagination;
        state.success = success;
        state.isLoading = false;
        state.total = total;
      }
    );
    builder.addCase(getAllCategories.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Create Categories
    builder.addCase(CreateCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CreateCategories.fulfilled, (state: any) => {
      state.isLoading = false;
      toast.success('Category created successfully');
    });
    builder.addCase(CreateCategories.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
    // Delete Category
    builder.addCase(deleteCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCategory.fulfilled, () => {
      toast.success('Category Deleted Successfully');
    });
    builder.addCase(deleteCategory.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
    // Edit Category
    builder.addCase(editCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editCategory.fulfilled, () => {
      toast.success('Category Edited Successfully');
    });
    builder.addCase(editCategory.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
  },
});

export default CategoriesSlice.reducer;
export const { handleCategoryChange } = CategoriesSlice.actions;
