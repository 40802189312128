import { useAppSelector } from '../hooks/useAppSelector';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { DashboardLayout } from '@layouts/index';
import { useEffect } from 'react';
import img from '../images/user.jpeg';

import {
  getAllLandlords,
  getAllAgents,
  getAllUser,
  getLoggedInUser,
  logoutUser,
  getAllUsersTotal,
  getAllVerifiedAccount,
} from 'features/users/UserSlice';
import { useNavigate } from 'react-router-dom';
import {
  changePage,
  fetchAllTransactions,
} from 'features/transactions/TransactionsSlice';
import {
  fetchAllProperties,
  getPendingApproval,
} from 'features/properties/PropertiesSlice';
import { Pagination, Table } from 'components';

// import { getAllUser,  searchUser } from 'features/users/UserSlice';
// import { Link } from 'react-router-dom';
const Dashboard = () => {
  const {
    loggedInUser,
    totalLandlords,
    totalAgents,
    totalUsers,
    verifiedUsers,
  } = useAppSelector((store) => store.user);
  const {
    data: tData,
    page,
    numOfPages: tNumOfPages,
    total: tTotal,
  } = useAppSelector((store) => store.transactions);

  const { total: propertyTotal, propertyPendingApproval } = useAppSelector(
    (store) => store.properties
  );
  const { total: totalReview } = useAppSelector((store) => store.reviews);

  // const url = { role: 'landlord', page: lPage };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/sign-in');
  };
  useEffect(() => {
    dispatch(getAllUser({}));
    dispatch(getAllLandlords({ role: 'landlord' }));
    dispatch(getAllAgents({ role: 'agent' }));
    dispatch(getAllUsersTotal({ role: 'user' }));
    dispatch(getLoggedInUser(null));
    dispatch(getAllVerifiedAccount({ verified: 'email' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(fetchAllTransactions({ page }));
    dispatch(fetchAllProperties(1));
    dispatch(getPendingApproval({ published: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      {/* <nav
        className='navbar navbar-expand-md navbar-light d-none d-md-flex'
        id='topbar'
      > */}
      {/* <div className='container-fluid'></div> */}
      <div className='container-fluid d-flex justify-content-between align-items-center d-none d-md-flex header-body'>
        {/* <form className='form-inline me-4 d-none d-md-flex'>
            <div
              className='input-group input-group-flush input-group-merge input-group-reverse'
              data-list='{"valueNames": ["name"]}'
            >
              <input
                type='search'
                className='form-control dropdown-toggle list-search'
                data-bs-toggle='dropdown'
                placeholder='Search'
                aria-label='Search'
              />

              <div className='input-group-text'>
                <i className='fe fe-search'></i>
              </div>
            </div>
          </form> */}
        {/* <div className='header'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-end'>
                <div className='col'>
                  <h6 className='header-pretitle'>Overview</h6> */}
        <div className=''>
          <h1 className='header-title'>
            Welcome, {loggedInUser?.firstname + ' ' + loggedInUser?.lastname}
          </h1>
        </div>
        {/* </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className='navbar-user d-flex align-items-center'>
          <div className='dropdown me-4 d-none d-md-flex'>
            <span
              // href='#!'
              className='navbar-user-link'
              role='button'
              data-bs-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span className='icon active'>
                <i className='fe fe-bell'></i>
              </span>
            </span>
          </div>
          <div className=' me-4 d-none d-md-flex'>
            <span
              // href='#!'
              className='navbar-user-link'
              role='button'
              data-bs-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span
                className='fe fe-refresh-cw'
                onClick={() => window.location.reload()}
              ></span>
            </span>
          </div>
          <div className=' me-4 d-none d-md-flex'>
            <span
              // href='#!'
              className='navbar-user-link'
              role='button'
              data-bs-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span className='fe fe-settings'></span>
            </span>
          </div>
          <div className='dropdown'>
            <span
              className='avatar avatar-md avatar-online dropdown-toggle'
              role='button'
              data-bs-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <img src={img} alt='...' className='avatar-img rounded-circle' />
              {/* <h6>
                {loggedInUser?.firstname} {loggedInUser?.lastname}
              </h6> */}
            </span>

            <div className='dropdown-menu dropdown-menu-end'>
              {/* <a href='profile-posts.html' className='dropdown-item'>
                  Profile
                </a>
                <a href='account-general.html' className='dropdown-item'>
                  Settings
                </a> */}
              <hr className='dropdown-divider' />
              <button className='dropdown-item' onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </nav> */}

      <div className='header'>
        <div className='container-fluid'>
          <div className=''>
            <div className='row align-items-end'>
              <div className='col'>
                {/* <h6 className='header-pretitle'>Overview</h6> */}

                <h1 className='header-title mt-3'>Dashboard</h1>
              </div>
              {/* <div className='col-auto'>
                <a href='#!' className='btn btn-primary lift'>
                  Create Report
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-lg-6 col-xl'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center gx-0'>
                  <div className='col'>
                    <h6 className='text-uppercase text-muted mb-2'>
                      Recent Transactions
                    </h6>

                    <span className='h2 mb-0'>{tTotal ? tTotal : 0}</span>

                    {/* <span className='badge bg-success-soft mt-n1'>+3.5%</span> */}
                  </div>
                  <div className='col-auto'>
                    <span className='h2 fe fe-dollar-sign text-muted mb-0'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center gx-0'>
                  <div className='col'>
                    <h6 className='text-uppercase text-muted mb-2'>
                      Properties
                    </h6>

                    <span className='h2 mb-0'>
                      {propertyTotal ? propertyTotal : 0}
                    </span>
                  </div>
                  <div className='col-auto'>
                    <span className='h2 fe fe-home text-muted mb-0'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center gx-0'>
                  <div className='col'>
                    <h6 className='text-uppercase text-muted mb-2'>
                      Landlords
                    </h6>

                    <span className='h2 mb-0'>
                      {totalLandlords ? totalLandlords : 0}
                    </span>
                  </div>
                  <div className='col-auto'>
                    {/* <div className='chart chart-sparkline'>
                      <canvas
                        className='chart-canvas'
                        id='sparklineChart'
                      ></canvas>
                    </div> */}
                    <div className='col-auto'>
                      <span className='h2 fe fe-users text-muted mb-0'></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center gx-0'>
                  <div className='col'>
                    <h6 className='text-uppercase text-muted mb-2'>Agents</h6>

                    <span className='h2 mb-0'>
                      {totalAgents ? totalAgents : 0}
                    </span>
                  </div>
                  <div className='col-auto'>
                    <span className='h2 fe fe-users text-muted mb-0'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center gx-0'>
                  <div className='col'>
                    <h6 className='text-uppercase text-muted mb-2'>Users</h6>

                    <span className='h2 mb-0'>
                      {totalUsers ? totalUsers : 0}
                    </span>
                  </div>
                  <div className='col-auto'>
                    <span className='h2 fe fe-users text-muted mb-0'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-lg-6 col-xl'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center gx-0'>
                  <div className='col'>
                    <h6 className='text-uppercase text-muted mb-2'>
                      Verified Users
                    </h6>

                    <span className='h2 mb-0'>
                      {verifiedUsers ? verifiedUsers : 0}
                    </span>
                  </div>
                  <div className='col-auto'>
                    <span className='h2 fe fe-user-check text-muted mb-0'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center gx-0'>
                  <div className='col'>
                    <h6 className='text-uppercase text-muted mb-2'>
                      Property Pending Approval
                    </h6>

                    <span className='h2 mb-0'>
                      {propertyPendingApproval ? propertyPendingApproval : 0}
                    </span>
                  </div>
                  <div className='col-auto'>
                    <span className='h2 fe fe-alert-triangle text-muted mb-0'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center gx-0'>
                  <div className='col'>
                    <h6 className='text-uppercase text-muted mb-2'>
                      Total Reviews
                    </h6>

                    <span className='h2 mb-0'>
                      {totalReview ? totalReview : 0}
                    </span>
                  </div>
                  <div className='col-auto'>
                    <span className='h2 fe fe-star text-muted mb-0'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'></div>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <h4>Recent Transactions</h4>
            {/* <PageHeader headerLink={false} title='Transactions' /> */}

            <Table
              data={tData}
              th={[
                'title',
                'owner',
                'intent',
                'Arrival Date',
                'Departure Date',
                'inspected',
                'inspection date',
                'availability',
                'Creation date',
                'status',
              ]}
              transactions
            />

            {tNumOfPages! > 1 && (
              <Pagination
                page={page}
                numOfPages={tNumOfPages}
                changePage={changePage}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
