import { clearWallet } from 'features/wallet/walletSlice';
import customFetch, { checkUnauthorizedResponse } from '../../utils/axios';
import {
  LoginUser,
  ChangePassword,
  ManageUser,
  CreateAdmin,
} from './LoginUser.interface';
import { NewUser } from './NewUser';
import { getAllUser } from './UserSlice';

// Register user
export const registerUserThunk = async (
  user: NewUser,
  thunkApi: any
): Promise<unknown> => {
  try {
    const resp = await customFetch.post('/auth/register', user);
    return resp.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};
// Login User
export const loginUserThunk = async (
  user: LoginUser,
  thunkApi: any
): Promise<unknown> => {
  try {
    const resp = await customFetch.post('/auth/login', user);

    return resp.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};
// Get User
// export const getUserThunk = async (_: any, thunkApi: any): Promise<unknown> => {
//   try {
//     const resp = await customFetch.get('/auth/me');
//     return resp.data.data;
//   } catch (error: any) {
//     return thunkApi.rejectWithValue(error.response.data.errors[0].message);
//   }

// Get allUser
// export const getAllUserThunk = async (
//   _: any,
//   thunkApi: any
// ): Promise<unknown> => {
//   try {
//     const resp = await customFetch.get('/users');
//     console.log(resp.data);

//     return resp.data;
//   } catch (error: any) {
//     return thunkApi.rejectWithValue(error.response.data.errors[0].message);
//   }
// };

// Get All User
export const getAllUserThunk = async (
  params: any = {},
  thunkApi: any
): Promise<any> => {
  let query = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  try {
    const resp = await customFetch.get(`/users?${query}`);

    return resp.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};

// Get All Landlords
export const getAllLandlordsThunk = async (
  params: any,
  thunkApi: any
): Promise<any> => {
  let query = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  try {
    const resp = await customFetch.get(`/users?${query}`);

    return resp.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};
// Get All Agents
export const getAllAgentsThunk = async (
  params: any,
  thunkApi: any
): Promise<any> => {
  let query = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  try {
    const resp = await customFetch.get(`/users?${query}`);

    return resp.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};
// Get All Users Total
export const getAllUsersTotalThunk = async (
  params: any,
  thunkApi: any
): Promise<any> => {
  let query = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  try {
    const resp = await customFetch.get(`/users?${query}`);

    return resp.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};

// Get Verified Users
export const getAllVerifiedAccountThunk = async (
  params: any,
  thunkApi: any
): Promise<any> => {
  let query = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  try {
    const resp = await customFetch.get(`/users?${query}`);

    return resp.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};

// Search user
export const searchUserThunk = async (
  key: any,
  thunkApi: any
): Promise<any> => {
  try {
    const resp = await customFetch.get(`/users/search?key=${key}`);

    return resp.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};

// Get User
export const getUserThunk = async (
  id: any,
  thunkApi: any
): Promise<unknown> => {
  try {
    thunkApi.dispatch(clearWallet());
    const resp = await customFetch.get(`/users/${id}`);
    if (resp) {
      return resp.data.data;
    }
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};

// Get LoggedInUser
export const getLoggedInUserThunk = async (
  _: any,
  thunkApi: any
): Promise<unknown> => {
  try {
    const resp = await customFetch.get('/auth/me');
    if (resp) {
      return resp.data.data;
    }
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};

// forgot Password
export const forgotPasswordThunk = async (data: any, thunkApi: any) => {
  try {
    const resp = await customFetch.post('/auth/forgotpassword', {
      email: data,
    });
    console.log(resp.data);
  } catch (error) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};
// Change Password
export const changePasswordThunk = async (
  { id, data }: ChangePassword,
  thunkApi: any
) => {
  try {
    const resp = await customFetch.put(`/users/change-password/${id}`, data);
    console.log(resp.data);
  } catch (error) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};

// Manage User
export const manageUserThunk = async (
  { id, action, path }: ManageUser,
  thunkApi: any
) => {
  try {
    const statePage = thunkApi.getState();
    const { page } = statePage.user;
    const resp = await customFetch.put(`/users/manage-user/${id}`, action);
    thunkApi.dispatch(getAllUser({ role: path, page: page }));
    return resp.data.data.disabled;
  } catch (error) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};
// Create Admin Role
export const createAdminThunk = async (data: CreateAdmin, thunkApi: any) => {
  try {
    const resp = await customFetch.post(`/users/`, data);
    console.log(resp.data);

    return resp.data;
  } catch (error) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};
