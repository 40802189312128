import { DashboardSideBar } from '@shared/navbars';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode; // Use ReactNode instead of Element[]
};

export const DashboardLayout: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <DashboardSideBar />
      <div className='main-content'>{children}</div>
    </div>
  );
};
