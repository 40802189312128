import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../features/users/UserSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setBodyClass } from '@helpers/setBodyClass';
import { toast } from 'react-toastify';
import { getAuthFromLocalStorage } from '@utils/localstorage';
import logo from '../images/Klogob.png';
const SignIn = () => {
  setBodyClass(
    'd-flex align-items-center bg-auth border-top border-top-2 border-primary'
  );

  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });

  const { isLoading } = useAppSelector((store) => store.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { email, password } = userData;
    if (!email || !password) {
      toast.error('Please fill all fields');
      return;
    }

    dispatch(loginUser(userData));

    // Add condition before navigate
    setTimeout(() => {
      if (getAuthFromLocalStorage()) navigate('/');
    }, 2000);
  };

  // Handle Routing on a higher level

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate('/');
  //   }
  // }, []);

  // if (isLoading) {
  //   return <h1>Loading...</h1>;
  // }

  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-5 col-xl-4 my-5'>
          {/* <h1 className='display-4 text-center mb-3'>Sign in</h1>
           */}
          <div className='display-4 text-center mb-3'>
            <img
              src={logo}
              className='navbar-brand-img mx-auto'
              alt='krent'
              style={{ width: '130px' }}
            />
          </div>
          <p className='text-muted text-center mb-5'>Welcome Back</p>

          <form onSubmit={handleSubmit}>
            <div className='form-group'>
              <label className='form-label'>Email Address</label>
              <input
                type='email'
                name='email'
                className='form-control'
                placeholder='name@address.com'
                onChange={handleChange}
                required
              />
            </div>

            <div className='form-group'>
              <div className='row'>
                <div className='col'>
                  <label className='form-label'>Password</label>
                </div>
                <div className='col-auto'>
                  <a
                    href='password-reset-cover.html'
                    className='form-text small text-muted'
                  >
                    Forgot password?
                  </a>
                </div>
              </div>

              <div className='input-group input-group-merge'>
                <input
                  className='form-control'
                  type='password'
                  name='password'
                  placeholder='Enter your password'
                  onChange={handleChange}
                  required
                />
                <span className='input-group-text'>
                  <i className='fe fe-eye'></i>
                </span>
              </div>
            </div>

            <button
              className='btn btn-lg w-100 btn-primary mb-3'
              disabled={isLoading}
            >
              Sign in
            </button>
            <div className='text-center'>
              <small className='text-muted text-center'>
                Don't have an account yet? <a href='sign-up.html'>Sign up</a>.
              </small>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
  // return (
  //   <div className='container'>
  //     <div className='row justify-content-center'>
  //       <div className='col-12 col-md-5 col-xl-4 my-5'>
  //         {/* heading */}
  //         <h1 className=' text-center mb-3 '>Sign in</h1>
  //         {/* Subheading */}
  //         <p className='text-muted text-center mb-5'>
  //           Free access to our dashboard.
  //         </p>

  //         <Form onSubmit={handleSubmit}>
  //           {/* email */}
  //           <Form.Group className='mb-3' controlId='formBasicEmail'>
  //             <Form.Label>Email address</Form.Label>
  //             <Form.Control
  //               type='email'
  //               name='email'
  //               placeholder='name@address.com'
  //               value={userData.email}
  //               onChange={handleChange}
  //             />
  //           </Form.Group>
  //           {/* password input */}
  //           <Form.Group className='mb-3' controlId='formBasicPassword'>
  //             <div className='row'>
  //               <Form.Label className='col'>Password</Form.Label>
  //               <Form.Text className=' col-auto text-muted '>
  //                 forgot Password
  //               </Form.Text>
  //             </div>
  //             <div className='input-group input-group-merge'>
  //               <Form.Control
  //                 type='password'
  //                 placeholder='Enter your password'
  //                 name='password'
  //                 className='border-end-0'
  //                 value={userData.password}
  //                 onChange={handleChange}
  //               />
  //               <span className='input-group-text bg-transparent'>
  //                 <AiOutlineEye />
  //               </span>
  //             </div>
  //           </Form.Group>
  //           {/* submit button */}
  //           <Button
  //             variant='primary'
  //             type='submit'
  //             className='btn btn-lg w-100 btn-primary mb-3'
  //           >
  //             Submit
  //           </Button>

  //           <div className='text-center'>
  //             <small className='text-muted text-center'>
  //               Don't have an account yet?
  //               <Link
  //                 to='/sign-up'
  //                 className='link-primary text-decoration-none'
  //               >
  //                 Sign up
  //               </Link>
  //             </small>
  //           </div>
  //         </Form>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default SignIn;
