import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import customFetch, { checkUnauthorizedResponse } from '@utils/axios';

import { toast } from 'react-toastify';

import {
  addAuthToLocalStorage,
  addUserToLocalStorage,
  getAuthFromLocalStorage,
  removeAuthFromLocalStorage,
  removeUserFromLocalStorage,
} from '../../utils/localstorage';

// import { DisplayUser } from './DisplayUser.interface';
import {
  getAllUserThunk,
  getUserThunk,
  loginUserThunk,
  manageUserThunk,
  registerUserThunk,
  changePasswordThunk,
  getLoggedInUserThunk,
  forgotPasswordThunk,
  searchUserThunk,
  getAllLandlordsThunk,
  getAllAgentsThunk,
  getAllUsersTotalThunk,
  getAllVerifiedAccountThunk,
  createAdminThunk,
} from './UserThunk';

interface AsyncState {
  isLoading: boolean;
}

interface AuthState extends AsyncState {
  data?: any | null;
  searchData?: any | null;
  page_count?: number | null;
  pagination?: { next: { page: number; limit: number } } | null;
  total?: number | null;
  totalLandlords?: any | null;
  totalAgents?: any | null;
  totalUsers?: any | null;
  isAuthenticated?: boolean;
  verifiedUsers?: number | null;
  numOfPages: number;
  page: number;
  searchKey: any;
  singleUser?: any | null;
  loggedInUser?: any | null;
}

const initialState: AuthState = {
  data: null,
  searchData: null,
  singleUser: null,
  loggedInUser: null,
  page_count: null,
  pagination: null,
  total: null,
  totalLandlords: null,
  totalAgents: null,
  totalUsers: null,
  verifiedUsers: null,
  isAuthenticated: getAuthFromLocalStorage(),
  isLoading: false,
  numOfPages: 1,
  page: 1,
  searchKey: '',
};

// Register User
export const registerUser = createAsyncThunk(
  'user/registerUser',
  registerUserThunk
);
// Login User
export const loginUser = createAsyncThunk('user/loginUser', loginUserThunk);

// Get All User Data
export const getAllUser = createAsyncThunk('allUser/getUser', getAllUserThunk);

// Get All Landlords total
export const getAllLandlords = createAsyncThunk(
  'allUser/getAllLandlords',
  getAllLandlordsThunk
);

// Get All Agents total
export const getAllAgents = createAsyncThunk(
  'allUser/getAllAgents',
  getAllAgentsThunk
);
// Get All Users total
export const getAllUsersTotal = createAsyncThunk(
  'allUser/getAllUsersTotal',
  getAllUsersTotalThunk
);
// Get All Verified Users
export const getAllVerifiedAccount = createAsyncThunk(
  'allUser/getAllVerifiedAccount',
  getAllVerifiedAccountThunk
);

// Search User
export const searchUser = createAsyncThunk('user/searchUser', searchUserThunk);

// Get Single User
export const getUser = createAsyncThunk('user/singleUser', getUserThunk);

// Get LoggedIn User
export const getLoggedInUser = createAsyncThunk(
  'loggedInUser/singleUser',
  getLoggedInUserThunk
);

// create admin role
export const createAdmin = createAsyncThunk(
  'user/createAdmin',
  createAdminThunk
);

// Clear State / LogOut
export const clearState = createAsyncThunk(
  'user/clearStore',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(logoutUser());
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  }
);

// Change Password
export const changePassword = createAsyncThunk(
  'user/resetPassword',
  changePasswordThunk
);
// forgot Password
export const forgotPassword = createAsyncThunk(
  'user/forgotPassword',
  forgotPasswordThunk
);

// Manage User
export const manageUser = createAsyncThunk('user/manageUser', manageUserThunk);

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.data = null;
      state.isAuthenticated = false;
      removeAuthFromLocalStorage();
      removeUserFromLocalStorage();
    },
    changePage: (state, { payload }) => {
      state.page = payload;
    },
    updateUserPage: (state, { payload }) => {
      state.page = payload;
    },
    handleUserOnChange: (state: any, { payload }: any) => {
      const { name, value } = payload;
      state[name] = value;
    },
  },
  extraReducers: (builder) => {
    // Register User
    builder.addCase(registerUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      addUserToLocalStorage(payload);
      state.isLoading = false;
      toast.success(
        'Registration was successful Please check your email to activate your account'
      );
    });
    builder.addCase(registerUser.rejected, (state, { payload }: any) => {
      state.isLoading = true;
      toast.error(payload);
    });

    // Login User
    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      addAuthToLocalStorage(state.isAuthenticated);
      addUserToLocalStorage(action.payload);
      toast.success('Login Successful');
      state.isLoading = false;
    });
    builder.addCase(loginUser.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Get  All User Data
    builder.addCase(getAllUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllUser.fulfilled, (state, { payload }: any) => {
      const { data, page_count, pagination, total } = payload;
      state.isLoading = false;
      state.data = data;
      state.page_count = page_count;
      state.pagination = pagination;
      state.total = total;
      state.numOfPages = Math.ceil(total / 20);
    });
    builder.addCase(getAllUser.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Get  All Landlords Total
    builder.addCase(getAllLandlords.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllLandlords.fulfilled, (state, { payload }: any) => {
      const { total } = payload;
      state.isLoading = false;
      state.totalLandlords = total;
    });
    builder.addCase(getAllLandlords.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Get  All Agents Total
    builder.addCase(getAllAgents.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllAgents.fulfilled, (state, { payload }: any) => {
      const { total } = payload;
      state.isLoading = false;
      state.totalAgents = total;
    });
    builder.addCase(getAllAgents.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
    // Get  All USers Total
    builder.addCase(getAllUsersTotal.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllUsersTotal.fulfilled, (state, { payload }: any) => {
      const { total } = payload;
      state.isLoading = false;
      state.totalUsers = total;
    });
    builder.addCase(getAllUsersTotal.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
    // Get  All Verified USers
    builder.addCase(getAllVerifiedAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllVerifiedAccount.fulfilled,
      (state, { payload }: any) => {
        const { total } = payload;
        state.isLoading = false;
        state.verifiedUsers = total;
      }
    );
    builder.addCase(
      getAllVerifiedAccount.rejected,
      (state, { payload }: any) => {
        state.isLoading = false;
        toast.error(payload);
      }
    );

    // Search User
    builder.addCase(searchUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchUser.fulfilled, (state, { payload }: any) => {
      const { data } = payload;
      state.isLoading = false;
      state.data = data;
      state.searchData = data;
    });
    builder.addCase(searchUser.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Get Single User
    builder.addCase(getUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUser.fulfilled, (state, { payload }: any) => {
      state.isLoading = false;
      state.singleUser = payload;
    });
    builder.addCase(getUser.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Get LoggedIn User
    builder.addCase(getLoggedInUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLoggedInUser.fulfilled, (state, { payload }: any) => {
      state.isLoading = false;
      state.loggedInUser = payload;
    });
    builder.addCase(getLoggedInUser.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Manage User
    builder.addCase(manageUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(manageUser.fulfilled, (state, { payload }: any) => {
      state.isLoading = false;
      if (payload === true) {
        toast.error('Account Disabled Successfully');
      } else {
        toast.success('Account Activated Successfully');
      }
    });
    builder.addCase(manageUser.rejected, (state, { payload }: any) => {
      state.isLoading = true;
      toast.error(payload);
    });

    // Change Password
    builder.addCase(changePassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.isLoading = false;
      toast.success('Password Changed successfully');
    });
    builder.addCase(changePassword.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
    // Forgot Password
    builder.addCase(forgotPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.isLoading = false;
      toast.success('Password Reset link as been sent to your email');
    });
    builder.addCase(forgotPassword.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Create Admin
    builder.addCase(createAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createAdmin.fulfilled, (state) => {
      state.isLoading = false;

      toast.success('User Created Successfully');
    });
    builder.addCase(createAdmin.rejected, (state, { payload }: any) => {
      state.isLoading = true;
      toast.error(payload);
    });
  },
});
export const { logoutUser, changePage, updateUserPage, handleUserOnChange } =
  UserSlice.actions;

export default UserSlice.reducer;
