// import { changePage } from 'features/properties/PropertiesSlice';
import { useAppDispatch } from '../hooks';

interface Page {
  page: number;
  numOfPages: number;
  changePage: any;
}

const Pagination: any = (prop: Page) => {
  const dispatch = useAppDispatch();

  const { numOfPages, page, changePage } = prop;

  const pages = Array.from({ length: numOfPages }, (_, index) => {
    return index + 1;
  });

  const handleNext = () => {
    let newPage = page + 1;
    if (newPage > numOfPages) {
      newPage = 1;
    }
    dispatch(changePage(newPage));
  };
  const handlePrev = () => {
    let newPage = page - 1;
    if (newPage < 1) {
      newPage = numOfPages;
    }
    dispatch(changePage(newPage));
  };

  return (
    <div className='row g-0'>
      {/* <!-- Pagination (prev) --> */}
      <div className='col list-pagination-prev pagination pagination-tabs justify-content-start'>
        {/* <li className='page-item' onClick={prev}> */}
        <span className='page-item ' onClick={handlePrev}>
          <button className='page-link'>
            <i className='fe fe-arrow-left ms-1'></i>
            Previous
          </button>
        </span>
        {/* </li> */}
      </div>

      {/* <!-- Pagination --> */}
      <ul className='col list-pagination pagination pagination-tabs justify-content-center'>
        {pages.map((pageNum) => {
          return (
            <li
              className={pageNum === page ? 'page-item active' : 'page-item'}
              key={pageNum}
            >
              <button
                className='page-link'
                onClick={() => {
                  dispatch(changePage(pageNum));
                }}
              >
                {pageNum}
              </button>
            </li>
          );
        })}
      </ul>

      {/* <!-- Pagination (next) --> */}
      <div className='col list-pagination-next pagination pagination-tabs justify-content-end'>
        <span className='page-item'>
          <button className='page-link' onClick={handleNext}>
            Next <i className='fe fe-arrow-right ms-1'></i>
          </button>
        </span>
      </div>
    </div>
    // <nav aria-label='Page navigation '>
    //   <div className='pagination justify-content-center'>
    //     <span className='page-item ' onClick={handlePrev}>
    //       <button className='page-link'>Previous</button>
    //     </span>
    //     <ul className='pagination justify-content-center'>
    //       {pages.map((pageNum) => {
    //         return (
    //           <li
    //             className={pageNum === page ? 'page-item active' : 'page-item'}
    //             key={pageNum}
    //           >
    //             <button
    //               className='page-link'
    //               onClick={() => {
    //                 dispatch(changePage(pageNum));
    //               }}
    //             >
    //               {pageNum}
    //             </button>
    //           </li>
    //         );
    //       })}
    //     </ul>
    //     <span className='page-item'>
    //       <button className='page-link' onClick={handleNext}>
    //         Next
    //       </button>
    //     </span>
    //   </div>
    // </nav>
  );
};

export default Pagination;
