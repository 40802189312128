import { Link } from 'react-router-dom';
import user from '../images/user.jpeg';
// import img from '../images/download.png';
import { manageUser } from 'features/users/UserSlice';
import moment from 'moment';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { clearWallet } from 'features/wallet/walletSlice';
import { userImgBaseUrl } from '@utils/urls';

const UsersTableData = ({ data }: any) => {
  const dispatch = useAppDispatch();

  const handleManageUser = (id: string, action: any) => {
    // this was added so as to remain on the same page after the action
    let path = window.location.pathname;
    path = path.slice(1, path.length - 2);
    if (path === 'customer') {
      path = 'user';
    }

    console.log(path);

    dispatch(manageUser({ id, action, path }));
  };
  // useEffect(() => {
  //   getWalletById()
  //   console.log(data);
  // }, []);
  return (
    <tbody className='list fs-base'>
      {data?.map((info: any) => {
        const {
          name,
          email,
          firstname,
          lastname,
          photo,
          id,
          phone,
          disabled,
          verified,
          createdAt,
        } = info;
        return (
          <tr key={id}>
            <td>
              <div className='form-check'>
                <input
                  className='form-check-input list-checkbox'
                  id='listCheckboxOne'
                  type='checkbox'
                />
                <label
                  className='form-check-label'
                  htmlFor='listCheckboxOne'
                ></label>
              </div>
            </td>
            <td>
              <div className='avatar avatar-md align-middle me-2'>
                <img
                  className='avatar-img rounded-circle'
                  src={photo === 'default.jpg' ? user : userImgBaseUrl + photo}
                  alt=''
                />
              </div>
              <Link
                to={`/user/${id}`}
                onClick={() => {
                  dispatch(clearWallet());
                }}
              >
                {firstname || lastname ? `${firstname} ${lastname} ` : name}
              </Link>
            </td>
            <td>
              <span className='item-email'>{email}</span>
            </td>
            <td>
              <span className='item-phone'>{phone}</span>
            </td>

            <td className='orders-status'>
              <span
                className={
                  disabled ? 'badge bg-danger-soft' : 'badge bg-success-soft'
                }
              >
                {disabled ? 'Disabled' : 'Active'}
              </span>
            </td>
            <td>
              <span className='item-price'>
                {verified.length !== 0
                  ? verified.map((verify: string, i: number) => (
                      <p key={i}>{verify}</p>
                    ))
                  : 'Not Verified'}
              </span>
            </td>

            <td>
              <span className='item-createdAt text-reset'>
                {moment(createdAt).format('MMM Do YY')}
              </span>
            </td>

            <td className='text-end'>
              <div className='dropdown'>
                {/* <a
                                  className='dropdown-ellipses dropdown-toggle'
                                  href='#!'
                                  role='button'
                                  data-bs-toggle='dropdown'
                                  aria-haspopup='true'
                                  aria-expanded='false'
                                >
                                  <i className='fe fe-more-vertical'></i>
                                </a> */}

                <button
                  className=' dropdown-ellipses dropdown-toggle btn btn-link'
                  type='button'
                  id='dropdownMenuButton1'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <i className='fe fe-more-vertical'></i>
                </button>
                <div className='dropdown-menu dropdown-menu-end'>
                  <Link to={`/properties/${id}`} className='dropdown-item'>
                    Edit
                  </Link>
                  <button className='dropdown-item btn btn-link'>Delete</button>
                  <button
                    className='dropdown-item btn btn-link'
                    onClick={() =>
                      handleManageUser(id, {
                        disabled: disabled ? false : true,
                      })
                    }
                  >
                    {disabled ? 'Enable' : 'Disable'}
                  </button>
                </div>
              </div>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default UsersTableData;
