import axios from 'axios';
import { clearState } from 'features/users/UserSlice';

import { getUserFromLocalStorage } from './localstorage';

// const url = process.env['REACT_APP_API_BASE_URL'];

const customFetch = axios.create({
  baseURL: process.env['REACT_APP_API_BASE_URL'],
  // baseURL: 'https://krent-dev-backend-r9b8p.ondigitalocean.app/api/v1',
  // baseURL: 'https://staging-api.krent.space/api/v1',
});

customFetch.interceptors.request.use((config: any) => {
  const user = getUserFromLocalStorage();
  if (user) {
    config.headers.common['Authorization'] = `Bearer ${user.token}`;
  }
  return config;
});

export const checkUnauthorizedResponse = (error: any, thunkApi: any) => {
  if (error.response.status === 401) {
    thunkApi.dispatch(clearState());
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    return thunkApi.rejectWithValue('Unauthorized! Logging Out...');
  }
  return thunkApi.rejectWithValue(error.response.data.errors[0].message);
};
export default customFetch;
