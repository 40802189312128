import { useEffect } from 'react';
import moment from 'moment';
import { useAppSelector } from '../hooks/useAppSelector';
import { useAppDispatch } from '../hooks/useAppDispatch';
import {
  handleTransactionsChange,
  registerPayment,
  clearForms,
  fetchSingleTransaction,
} from 'features/transactions/TransactionsSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { DashboardLayout } from '@layouts/DashboardLayout';

const SingleTransaction = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, singleTransaction } = useAppSelector(
    (store) => store.transactions
  );

  const { amountPaid, reference, channel } = useAppSelector(
    (store) => store.transactions
  );

  const handleInput = (e: any) => {
    dispatch(
      handleTransactionsChange({
        name: e.target.name,
        value: e.target.value,
      })
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(registerPayment(id));
    dispatch(clearForms());
  };

  useEffect(() => {
    dispatch(fetchSingleTransaction(`${id}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading || singleTransaction === null) {
    return <h1>Loading</h1>;
  }

  const {
    id: ID,
    property_avalability_status,
    status,
    createdAt,
    arrival_date,
    departure_date,
    inspected,
    inspection_date,
    bill,
    renter,
    owner,
    inspection_schedules,
    listing,
  } = singleTransaction;

  return (
    <DashboardLayout>
      <div className='container-fluid'>
        <button
          className='btn btn-dark mt-4'
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </button>
      </div>
      <div className='container mt-5 px-5'>
        <div className='row'>
          {/* listing */}
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <h2 className='card-title text-center'>Listing</h2>

                <p className='text-center mb-2'>
                  <span className='badge bg-secondary-soft'>
                    {listing['id']}
                  </span>
                </p>

                <p className='text-center mb-4'>
                  <span className='mb-0 badge bg-primary p-2 px-3 '>
                    {listing['intent'].toUpperCase()}
                  </span>
                </p>

                <div className='row'>
                  <div className='col-6'>
                    <h3 className='card-title text-center mt-3'>
                      Listing Info
                    </h3>

                    <div className='list-group list-group-flush mb-0'>
                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>Title</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted'>
                              {listing['title']}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>Price</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted fw-bold'>
                              {listing['currency']}{' '}
                              {listing['price'].toLocaleString()}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>Coordinates</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted '>
                              {listing['location']['coordinates'][0]},{' '}
                              {listing['location']['coordinates'][1]}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>Country</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted '>
                              {listing['location']['country']}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>City</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted '>
                              {listing['location']['city']}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>State</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted '>
                              {listing['location']['state']}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>Address</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted '>
                              {listing['location']['formattedAddress']}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='col-6'>
                    <h3 className='card-title text-center mt-3'>User Info</h3>

                    <p className='text-center mb-2'>
                      <span className='badge bg-secondary-soft'>
                        {listing['user']['id']}
                      </span>
                    </p>

                    <div className='list-group list-group-flush mb-0'>
                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>Name</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted'>
                              {listing['user']['name']}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>Email</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted'>
                              {listing['user']['email']}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>Role</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted'>
                              {listing['user']['role'].toUpperCase()}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>Wallet</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted'>
                              {listing['user']['wallet']}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row align-items-center justify-content-between mt-3'>
                      <div className='col-auto mb-2'>
                        {listing['canTransact'] ? (
                          <small>
                            <span className='text-success'>●</span> Account
                            Enabled
                          </small>
                        ) : (
                          <small>
                            <span className='text-danger'>●</span> Account
                            Disabled
                          </small>
                        )}
                      </div>

                      <div className='col-auto'>
                        {listing['canTransact'] ? (
                          <small>
                            <span className='text-success'>●</span> Transactions
                            Enabled
                          </small>
                        ) : (
                          <small>
                            <span className='text-danger'>●</span> Transactions
                            Disabled
                          </small>
                        )}
                      </div>
                    </div>
                  </div> */}

                  {/* owner */}
                  <div className='col-12 col-md-6'>
                    <div className='card'>
                      <div className='card-body'>
                        <h2 className='card-title text-center'>Owner</h2>

                        <p className='text-center mb-2'>
                          <span className='badge bg-secondary-soft'>
                            {owner['id']}
                          </span>
                        </p>
                        <p className='small text-center text-muted mb-3'>
                          {owner['email']}
                        </p>

                        <div className='list-group list-group-flush mb-0'>
                          <div className='list-group-item'>
                            <div className='row align-items-center'>
                              <div className='col'>
                                <small>Name</small>
                              </div>
                              <div className='col-auto'>
                                <small className='small text-muted'>
                                  {owner['name']}
                                </small>
                              </div>
                            </div>
                          </div>

                          <div className='list-group-item'>
                            <div className='row align-items-center'>
                              <div className='col'>
                                <small>Phone</small>
                              </div>
                              <div className='col-auto'>
                                <small className='small text-muted'>
                                  +{owner['phone']}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* renter */}
                    <div className='card'>
                      <div className='card-body'>
                        <h2 className='card-title text-center'>Renter</h2>

                        <p className='text-center mb-2'>
                          <span className='badge bg-secondary-soft'>
                            {renter['id']}
                          </span>
                        </p>
                        <p className='small text-center text-muted mb-3'>
                          {renter['email']}
                        </p>

                        <div className='list-group list-group-flush mb-0'>
                          <div className='list-group-item'>
                            <div className='row align-items-center'>
                              <div className='col'>
                                <small>Name</small>
                              </div>
                              <div className='col-auto'>
                                <small className='small text-muted'>
                                  {renter['name']}
                                </small>
                              </div>
                            </div>
                          </div>

                          <div className='list-group-item'>
                            <div className='row align-items-center'>
                              <div className='col'>
                                <small>Phone</small>
                              </div>
                              <div className='col-auto'>
                                <small className='small text-muted'>
                                  +{renter['phone']}
                                </small>
                              </div>
                            </div>
                          </div>

                          <div className='list-group-item'>
                            <div className='row align-items-center'>
                              <div className='col'>
                                <small>Inspections</small>
                              </div>
                              <div className='col-auto'>
                                <small className='small text-primary'>
                                  {renter['inspections'].toLocaleString()}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end of renter */}
                  </div>
                  {/*end owner */}
                </div>
              </div>
            </div>
          </div>
          {/* end of listing */}

          <div className='col-12 col-md-6'>
            <div className='card'>
              <div className='card-body'>
                <h2 className='card-title text-center'>Property Info</h2>

                <p className='text-center mb-4'>
                  <span className='badge bg-secondary-soft'>{ID}</span>
                </p>

                <div className='row g-0 border-top border-bottom'>
                  <div className='col-6 py-4 text-center'>
                    <h6 className='text-uppercase text-muted'>Availability</h6>

                    <h2
                      className={`mb-0 badge ${
                        property_avalability_status === 'available'
                          ? 'bg-success'
                          : 'bg-danger'
                      }`}
                    >
                      {property_avalability_status === 'available'
                        ? 'Available'
                        : 'Unavailable'}
                    </h2>
                  </div>
                  <div className='col-6 py-4 text-center border-start'>
                    <h6 className='text-uppercase text-muted'>Status</h6>

                    <h2
                      className={`mb-0 badge ${
                        status === 'customer-accepted'
                          ? 'bg-success'
                          : 'bg-danger'
                      }`}
                    >
                      {status === 'customer-accepted' ? 'Accepted' : 'Canceled'}
                    </h2>
                  </div>
                </div>

                <div className='list-group list-group-flush mb-4'>
                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Listed</small>
                      </div>
                      <div className='col-auto'>
                        <time className='small text-muted'>
                          {moment(createdAt).format('DD/MM/YY')}
                        </time>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Arrival</small>
                      </div>
                      <div className='col-auto'>
                        <time className='small text-muted'>
                          {moment(arrival_date).format('DD/MM/YY')}
                        </time>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Departure</small>
                      </div>
                      <div className='col-auto'>
                        <time className='small text-muted'>
                          {moment(departure_date).format('DD/MM/YY')}
                        </time>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Inspection Date</small>
                      </div>
                      <div className='col-auto'>
                        <small className='small text-muted'>
                          {moment(inspection_date).format('DD/MM/YY')}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className='row align-items-center justify-content-between mt-3'>
                    <div className='col-auto'>
                      {inspected ? (
                        <small>
                          <span className='text-success'>●</span> Inspected
                        </small>
                      ) : (
                        <small>
                          <span className='text-danger'>●</span> Not Inspected
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='card'>
              <div className='card-body'>
                <h2 className='card-title text-center'>Bill</h2>

                <div className='list-group list-group-flush mb-4'>
                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Property Price</small>
                      </div>
                      <div className='col-auto'>
                        <small className='small text-muted'>
                          {bill?.propertyPrice?.toLocaleString()}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Caution Fee</small>
                      </div>
                      <div className='col-auto'>
                        <small className='small text-muted'>
                          {bill?.cautionFee?.toLocaleString()}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Krent Admin Charge</small>
                      </div>
                      <div className='col-auto'>
                        <small className='small text-muted'>
                          {bill?.krentAdminCharge?.toLocaleString()}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Tax</small>
                      </div>
                      <div className='col-auto'>
                        <small className='small text-muted'>
                          {bill?.tax?.toLocaleString()}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <small>Total</small>
                      </div>
                      <div className='col-auto'>
                        <small className='small text-muted fw-bold'>
                          {bill?.total?.toLocaleString()}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='row align-items-center justify-content-between'>
									<div className='col-auto'>
										{verified ? (
											<small>
												<span className='text-success'>●</span> Verified
											</small>
										) : (
											<small>
												<span className='text-danger'>●</span> Unverified
											</small>
										)}
									</div>
									<div className='col-auto'>
										{canTransact ? (
											<small>
												<span className='text-success'>●</span> Transactions
												Enabled
											</small>
										) : (
											<small>
												<span className='text-danger'>●</span> Transactions
												Disabled
											</small>
										)}
									</div>
								</div> */}
              </div>
            </div>
          </div>

          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <h2 className='card-title text-center'>Inspection Schedules</h2>

                <div className='row'>
                  <div className='col-6'>
                    <h3 className='card-title text-center mt-3'>Option 1</h3>

                    <div className='list-group list-group-flush mb-0'>
                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>From</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted'>
                              {moment(
                                inspection_schedules?.option1?.from
                              ).format('DD/MM/YY')}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>To</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted'>
                              {moment(inspection_schedules?.option1?.to).format(
                                'DD/MM/YY'
                              )}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <h3 className='card-title text-center mt-3'>Option 2</h3>

                    <div className='list-group list-group-flush mb-0'>
                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>From</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted'>
                              {moment(
                                inspection_schedules?.option2?.from
                              ).format('DD/MM/YY')}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className='list-group-item'>
                        <div className='row align-items-center'>
                          <div className='col'>
                            <small>To</small>
                          </div>
                          <div className='col-auto'>
                            <small className='small text-muted'>
                              {moment(inspection_schedules?.option2?.to).format(
                                'DD/MM/YY'
                              )}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* register Payment */}
          {status === 'customer-accepted' && (
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <h2 className='card-title text-center'>Register Payment</h2>

                  <form className='mt-4' onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className='col-12 col-md-6'>
                        <div className='form-group'>
                          <label className='form-label'>Amount Paid</label>

                          <input
                            className='form-control'
                            type='number'
                            name='amountPaid'
                            value={amountPaid}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div className='col-12 col-md-6'>
                        <div className='form-group'>
                          <label className='form-label'>Channel</label>

                          {/* <input
													className='form-control'
													type='text'
													name='channel'
													value={channel}
													onChange={handleInput}
												/> */}
                          <select
                            className='form-select mb-3'
                            value={channel}
                            name='channel'
                            onChange={handleInput}
                          >
                            <option>Bank transfer</option>
                            <option>Cash</option>
                            <option>Cheque</option>
                            <option>Online payment</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label'>Reference</label>

                          <input
                            className='form-control'
                            type='text'
                            name='reference'
                            value={reference}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                    </div>
                    <button className='btn btn-primary' type='submit'>
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}

          {/* end of register payment */}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SingleTransaction;
