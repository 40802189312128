import { configureStore } from '@reduxjs/toolkit';
import PropertiesSlice from './features/properties/PropertiesSlice';
import TransactionsSlice from './features/transactions/TransactionsSlice';
import UserSlice from './features/users/UserSlice';
import ReviewSlice from './features/reviews/ReviewSlice';
import amenitiesSlice from './features/amenities/amenitiesSlice';
import categorySlice from './features/categories/categorySlice';
import kindSlice from './features/kind/kindSlice';
import walletSlice from './features/wallet/walletSlice';

export const store = configureStore({
  reducer: {
    user: UserSlice,
    properties: PropertiesSlice,
    transactions: TransactionsSlice,
    reviews: ReviewSlice,
    amenities: amenitiesSlice,
    categories: categorySlice,
    kinds: kindSlice,
    wallet: walletSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
