// User
export const addUserToLocalStorage = (user: any) => {
  localStorage.setItem('admin-user', JSON.stringify(user));
};
export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem('admin-user');
  const user = result ? JSON.parse(result) : null;
  return user;
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem('admin-user');
};

// Authentication

export const addAuthToLocalStorage = (user: any) => {
  localStorage.setItem('isAuthenticated', JSON.stringify(user));
};
export const getAuthFromLocalStorage = () => {
  const result = localStorage.getItem('isAuthenticated');
  const user = result ? JSON.parse(result) : null;
  return user;
};

export const removeAuthFromLocalStorage = () => {
  localStorage.removeItem('isAuthenticated');
};
