import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks';
import {
  deleteProperty,
  getProperty,
  handleEdit,
  togglePublish,
  // addPropertyImage,
} from 'features/properties/PropertiesSlice';
import { useParams } from 'react-router-dom';
import { DashboardLayout } from '@layouts/DashboardLayout';
import { PriceCard } from '@shared/PriceCard';
// import { DisplayProperties } from 'features/properties/DisplayProperties';

const starArray = [...Array(5).keys()].map((i) => i + 1);

const Rating = ({ rating }: any) => {
  return (
    <>
      {starArray.map((i) => (
        <i
          key={i}
          className={`fe fe-star ${
            rating >= i ? 'text-success' : 'text-secondary'
          }`}
        />
      ))}
    </>
  );
};

const SingleProperty = () => {
  const { id }: any = useParams()!;

  const navigate = useNavigate();

  const { singleProperty, isLoading } = useAppSelector(
    (store) => store.properties
  );

  // const [form, setForm]: any = useState({});
  // const [coverImage1, setCoverImage1]: any = useState([]);
  // const [otherImages1, setOtherImages1]: any = useState([]);
  // const [floorPlans1, setFloorPlans1]: any = useState([]);
  const dispatch = useAppDispatch();

  // const handleChange = (e: any) => {
  // 	const value = e.target.files;
  // 	const name = e.target.name;

  // 	if (name === 'coverImage') {
  // 		setCoverImage1(value);
  // 	}

  // 	if (name === 'floorPlans') {
  // 		setFloorPlans1(value);
  // 	}
  // 	if (name === 'otherImages') {
  // 		setOtherImages1(value);
  // 	}
  // 	setForm({ ...form, [name]: value });
  // };

  // const handleSubmit = (e: any) => {
  // 	e.preventDefault();

  // 	const formData = new FormData();
  // 	// if (form.coverImage) {
  // 	//   formData.append('coverImage', form.coverImage);
  // 	// }
  // 	// if (form.floorPlans) {
  // 	//   formData.append('floorPlans', form.floorPlans);
  // 	// }
  // 	// if (form.otherImages) {
  // 	//   formData.append('otherImages', form.otherImages);
  // 	// }

  // 	for (let i = 0; i < otherImages1.length; i++) {
  // 		formData.append('otherImages', otherImages1[i]);
  // 	}
  // 	for (let i = 0; i < coverImage1.length; i++) {
  // 		formData.append('coverImage', coverImage1[i]);
  // 	}
  // 	for (let i = 0; i < floorPlans1.length; i++) {
  // 		formData.append('floorPlans', floorPlans1[i]);
  // 	}
  // 	console.log(otherImages1);
  // 	console.log(coverImage1);
  // 	console.log(floorPlans1);

  // 	dispatch(addPropertyImage({ id, data: formData }));
  // };

  useEffect(() => {
    dispatch(getProperty(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading || singleProperty === null) {
    return <h1>Loading...</h1>;
  }

  const {
    title,
    otherImages,
    coverImage,
    // floorPlans,
    kind,
    space,
    // iBuy,
    intent,
    currency,
    category,
    price,
    cycle,
    floor,
    area,
    size,
    garages,
    description,
    summary,
    bathrooms,
    bedrooms,
    beds,
    guests,
    location,
    published,
    user,
    ratings,
    ratingsAverage,
    video_url,
    virtual_tour_url,
  } = singleProperty!;

  return (
    <DashboardLayout>
      <div className='container-fluid'></div>
      <div className='container mt-5 px-5'>
        <div className='col mb-3'>
          <button
            className='btn btn-dark mb-4'
            onClick={() => navigate('/properties')}
          >
            Back
          </button>
          <h3 className='fw-bolder mb-1'>{title}</h3>
          <p className='text-secondary fs-14'>{location?.formattedAddress}</p>
          <p className='text-primary fw-bold'>
            {category?.title} - {kind?.title}{' '}
            {space && <span> - {space['title']}</span>}
          </p>
        </div>

        <div className='row mb-4'>
          <div className='col-12 col-md-8'>
            <img src={coverImage} alt='' className='rounded img-fluid mb-3' />
          </div>

          <div className='col-12 col-md-4'>
            <div className='row'>
              <div className='col-6 col-md-12 mb-md-3 mb-0 '>
                {otherImages && (
                  <img
                    src={otherImages[0]}
                    alt=''
                    className='rounded img-fluid'
                  />
                )}
              </div>
              <div className='col-6 col-md-12'>
                {otherImages && (
                  <img
                    src={otherImages[1]}
                    alt=''
                    className='rounded img-fluid'
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {!published && (
          <div className='row'>
            <div className='col-12 col-md-8'>
              <div
                role='alert'
                className='alert alert-warning alert-dismissible fade show'
              >
                This property is not published
              </div>
            </div>
          </div>
        )}
        <div className='row'>
          <div className='col-12 col-md-8'>
            <div className='card mb-4'>
              <div className='card-body'>
                <div className='row row-cols-3 row-cols-md-4 row-cols-lg-4 g-4'>
                  <div>
                    <p className='mb-1'>Bedrooms</p>
                    <span className='fs-14 fw-bold'>
                      <i className='fa-light fa-bed-front'></i>&nbsp; {bedrooms}
                    </span>
                  </div>

                  <div>
                    <p className='mb-1'>Bathrooms</p>
                    <span className='fs-14 fw-bold'>
                      <i className='fa-light fa-bath'></i>&nbsp; {bathrooms}
                    </span>
                  </div>

                  <div>
                    <p className='mb-1'>Square Area</p>
                    <span className='fs-14 fw-bold'>
                      <i className='fa-light fa-ruler-triangle'></i>&nbsp; 6 x
                      8m<sup>2</sup>{' '}
                    </span>
                  </div>

                  <div>
                    <p className='mb-1'>Status</p>
                    <span className='fs-14 fw-bold text-capitalize'>
                      <i className='fa-light fa-sign-hanging'></i>&nbsp; For{' '}
                      {intent}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <h4 className='fw-bolder'>About this home</h4>
            <p className='text-secondary fs-14'>{description}</p>

            <div className='col-12'>
              <PriceCard
                currency={currency}
                type={intent}
                price={price}
                duration={cycle}
              />
            </div>

            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  {/* <!-- List group --> */}
                  <div className='list-group list-group-flush my-n3'>
                    <div className='list-group-item'>
                      <div className='row align-items-center'>
                        <div className='col'>
                          <h5 className='mb-0'>Video URL</h5>
                        </div>
                        <div className='col-auto'>
                          <a
                            href={video_url}
                            className='small'
                            target='_blank'
                            rel='noreferrer'
                          >
                            {video_url}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='list-group-item'>
                      <div className='row align-items-center'>
                        <div className='col'>
                          <h5 className='mb-0'>Virtual Tour URL</h5>
                        </div>
                        <div className='col-auto'>
                          <a
                            href={virtual_tour_url}
                            className='small'
                            target='_blank'
                            rel='noreferrer'
                          >
                            {virtual_tour_url}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-4'>
            <button
              className='btn btn-primary mb-3 w-100'
              onClick={() => {
                navigate(`/edit-property/${id}`);
                dispatch(
                  handleEdit({
                    id,
                    title,
                    otherImages,
                    coverImage,
                    // floorPlans,
                    kind: kind?._id,
                    space: space?._id,
                    // iBuy,
                    intent,
                    currency,
                    category: category?._id,
                    price,
                    cycle,
                    description,
                    summary,
                    bathrooms,
                    bedrooms,
                    beds,
                    garages,
                    size,
                    floor,
                    area,
                    guests,
                    address: location?.formattedAddress,
                    user,
                    ratings,
                    ratingsAverage,
                    video_url,
                    virtual_tour_url,
                  })
                );
              }}
            >
              Edit Property
            </button>
            <button
              className='btn btn-danger mb-3 w-100'
              onClick={() => {
                dispatch(deleteProperty(id));
                setTimeout(() => {
                  navigate('/properties');
                }, 2000);
              }}
            >
              Delete Property
            </button>
            <button
              className={`btn btn-${
                published ? 'danger' : 'success'
              } mb-3 w-100`}
              onClick={() => {
                dispatch(
                  togglePublish({
                    id,
                    payload: { published: published ? false : true },
                  })
                );
              }}
            >
              {published ? 'Unpublish Property' : 'Publish Property'}
            </button>

            <div className='card'>
              <div className='card-body text-center'>
                <p className='text-dark m-0 fw-bold'>
                  Rating: <br /> <Rating rating={ratings} />
                </p>
                <p className='text-dark m-0 fw-bold'>
                  Average Rating: <br /> <Rating rating={ratingsAverage} />
                </p>
              </div>
            </div>

            <div className='card'>
              <div className='card-body text-center'>
                <h2 className='card-title'>
                  <a href='#!'>{user['name']}</a>
                </h2>

                <p className='small text-muted mb-3'>{user['email']}</p>

                <p className='card-text'>
                  <span className='badge bg-secondary-soft'>
                    {user['role'].toLocaleUpperCase()}
                  </span>
                  <span className='badge bg-secondary-soft'>{user['id']}</span>
                </p>
              </div>

              {/* <!-- Footer --> */}
              <div className='card-footer card-footer-boxed'>
                <div className='row align-items-center justify-content-between'>
                  <div className='col-auto'>
                    {/* <!-- Status --> */}
                    <small>
                      {user['disabled'] ? (
                        <p>
                          <span className='text-danger'>●</span> Inactive
                        </p>
                      ) : (
                        <p>
                          <span className='text-success'>●</span> Active
                        </p>
                      )}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Card --> */}
            <div className='card'>
              <div className='card-body'>
                {/* <!-- List group --> */}
                <div className='list-group list-group-flush my-n3'>
                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <h5 className='mb-0'>Coordinates</h5>
                      </div>
                      <div className='col-auto'>
                        <small className='text-muted'>
                          {location['coordinates'][0]},{' '}
                          {location['coordinates'][1]}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <h5 className='mb-0'>Street</h5>
                      </div>
                      <div className='col-auto'>
                        <small className='text-muted'>
                          {location['street']}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <h5 className='mb-0'>City</h5>
                      </div>
                      <div className='col-auto'>
                        <small className='text-muted'>{location['city']}</small>
                      </div>
                    </div>
                  </div>
                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <h5 className='mb-0'>State</h5>
                      </div>
                      <div className='col-auto'>
                        <small className='text-muted'>
                          {location['state']}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <h5 className='mb-0'>Country</h5>
                      </div>
                      <div className='col-auto'>
                        <small className='text-muted'>
                          {location['country']}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

{
  /* <h1>{slug}</h1>
<div>
  <h3>Other Images</h3>
  {otherImages?.length === 0
    ? 'No images'
    : otherImages?.map((image: string, index: number) => (
        <ul key={index}>
          <li>{image}</li>
        </ul>
      ))}
  <h3>Cover image</h3>
  <p>{coverImage}</p>
  <h3>Floor Plan</h3>
  {floorPlans?.length === 0
    ? 'No images'
    : floorPlans?.map((image: string, index: number) => (
        <ul key={index}>
          <li>{image}</li>
        </ul>
      ))}
</div>
<form onSubmit={handleSubmit}>
  <div>
    <label htmlFor='otherImages'>Other Images</label>
    <input
      type='file'
      multiple
      name='otherImages'
      id='otherImages'
      onChange={handleChange}
    />
  </div>
  <div>
    <label htmlFor='coverImage'>Cover Image</label>
    <input
      type='file'
      name='coverImage'
      id='coverImage'
      onChange={handleChange}
    />
  </div>
  <div>
    <label htmlFor='floorPlans'>Floor plan</label>
    <input
      type='file'
      multiple
      name='floorPlans'
      id='floorPlans'
      onChange={handleChange}
    />
  </div>
  <button type='button' className='btn btn-primary' onClick={handleEdit}>
    Edit
  </button>
  <button type='submit' className='btn btn-success'>
    Submit
  </button>
</form> */
}

export default SingleProperty;
