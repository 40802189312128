import { useAppSelector } from '../hooks/useAppSelector';
import { useAppDispatch } from '../hooks/useAppDispatch';
import {
  handlePropertyChange,
  clearFilters,
  fetchAllProperties,
} from 'features/properties/PropertiesSlice';

const SearchBar = ({ property }: any) => {
  const dispatch = useAppDispatch();
  const properties = useAppSelector((store) => {
    return store.properties;
  });

  const { search, bedrooms, intent, isLoading } = properties;
  const location = properties['location.city'];
  const handleSearch = (e: any) => {
    if (isLoading) return;
    dispatch(
      handlePropertyChange({ name: e.target.name, value: e.target.value })
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let query;
    if (intent) {
      query = { page: 1, intent };
    }
    if (bedrooms) {
      query = { ...query, bedrooms };
    }
    if (location) {
      query = { ...query, 'location.city': location };
    }
    dispatch(fetchAllProperties(query));
    dispatch(clearFilters());
  };
  return (
    <div className='card-header'>
      <div className='row align-items-center'>
        {!property && (
          <div className='col'>
            <form>
              <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                <input
                  className='form-control list-search'
                  type='text'
                  name='search'
                  value={search}
                  onChange={handleSearch}
                  placeholder='Search'
                />
                <span className='input-group-text'>
                  <i className='fe fe-search'></i>
                </span>
              </div>
            </form>
          </div>
        )}
        {/* <div className='col-auto me-n3'>
          <form>
            <select
              className='form-select form-select-sm form-control-flush'
              data-choices='{"searchEnabled": false}'
            >
              <option value='5 per page'>10 per page</option>
              <option value='10 per page'>20 per page</option>
              <option value='all'>All</option>
            </select>
          </form>
        </div> */}
        {property && (
          <div className='col-auto ms-auto'>
            <div className='dropdown'>
              <button
                className='btn btn-sm btn-white'
                type='button'
                data-bs-toggle='dropdown'
                data-bs-auto-close='outside'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <i className='fe fe-sliders me-1'></i> Filter{' '}
                <span className='badge bg-primary ms-1 d-none'>0</span>
              </button>

              <form className='dropdown-menu dropdown-menu-end dropdown-menu-card'>
                <div className='card-header'>
                  <h4 className='card-header-title'>Filters</h4>

                  <button
                    className='btn btn-sm btn-link text-reset d-none'
                    type='reset'
                  >
                    <small>Clear filters</small>
                  </button>
                </div>
                <div className='card-body'>
                  <div className='list-group list-group-flush mt-n4 mb-4'>
                    {/* Intent */}
                    <div className='list-group-item'>
                      <div className='row'>
                        <div className='col'>
                          <small>Intent</small>
                        </div>
                        <div className='col-auto'>
                          <select
                            className='form-select form-select-sm'
                            name='intent'
                            value={intent}
                            onChange={handleSearch}
                            data-choices='{"searchEnabled": false}'
                          >
                            <option value=''>All</option>
                            <option value='shortlet'>Shortlet</option>
                            <option value='sale'>Sale</option>
                            <option value='long lease'>Long Lease</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* Category */}
                    {/* <div className='list-group-item'>
                    <div className='row'>
                      <div className='col'>
                        <small>Category</small>
                      </div>
                      <div className='col-auto'>
                        <select
                          className='form-select form-select-sm'
                          name='category'
                          value={category}
                          onChange={handleSearch}
                          data-choices='{"searchEnabled": true}'
                        >
                          <option value=''>Any</option>

                          <option value='Rental unit'>Rental unit</option>
                          <option value='Condominium (Condo)'>
                            Condominium (Condo)
                          </option>
                          <option value='Casa particular'>
                            Casa particular
                          </option>
                          <option value='Serviced Apartment'>
                            Serviced Apartment
                          </option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                    <div className='list-group-item'>
                      <div className='row'>
                        <div className='col'>
                          <small>Bedrooms</small>
                        </div>
                        <div className='col-auto'>
                          <select
                            className='form-select form-select-sm'
                            name='bedrooms'
                            value={bedrooms}
                            onChange={handleSearch}
                            data-choices='{"searchEnabled": true}'
                          >
                            <option value=''>All</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='list-group-item'>
                      <div className='row'>
                        <div className='col'>
                          <small>Location</small>
                        </div>
                        <div className='col-auto'>
                          <select
                            className='form-select form-select-sm'
                            name='location.city'
                            value={location}
                            onChange={handleSearch}
                            data-choices='{"searchEnabled": true}'
                          >
                            <option value=''>All</option>
                            <option value='Lagos'>Lagos</option>
                            <option value='Abuja'>Abuja</option>
                            <option value='Kaduna'>Kaduna</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    className='btn w-100 btn-primary'
                    type='button'
                    onClick={handleSubmit}
                  >
                    Apply filter
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
