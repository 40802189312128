import { Navigate, Outlet } from 'react-router-dom';
import { getUserFromLocalStorage } from '../utils/localstorage';

const ProtectedRoute = () => {
  const user = getUserFromLocalStorage();
  if (!user) {
    return <Navigate to='/sign-in' />;
  }
  return (
    <>
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
