import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import customFetch, { checkUnauthorizedResponse } from '../../utils/axios';
import { toast } from 'react-toastify';

interface AsyncState {
  isLoading: boolean;
}
interface RegisterPayment {
  amountPaid?: any;
  reference?: any | string;
  channel?: any | string;
}
interface AuthState extends AsyncState, RegisterPayment {
  // data?: DisplayProperties | null;
  data?: any | null;
  success?: boolean;
  page_count?: number;
  pagination?: any;
  total?: number;
  page?: number;
  numOfPages?: number;
  singleTransaction?: any;
  individualTransactions?: any;
  paymentResponse?: any;
}

const initialPaymentState = {
  amountPaid: 0,
  channel: 'Bank transfer',
  reference: '',
};

const initialState: AuthState = {
  data: null,
  individualTransactions: null,
  singleTransaction: null,
  success: false,
  isLoading: false,
  pagination: {},
  total: 0,
  page_count: 0,
  page: 1,
  numOfPages: 1,
  paymentResponse: null,
  ...initialPaymentState,
};

// Fetch All Transactions
export const fetchAllTransactions = createAsyncThunk(
  'transactions/AllTransactions',
  async (params: any, thunkApi): Promise<unknown> => {
    let query = Object.keys(params)
      .map((key) => key + '=' + params[key])
      .join('&');
    try {
      const resp = await customFetch.get(`/transactions?${query}`);

      return resp.data;
    } catch (error: any) {
      return checkUnauthorizedResponse(error, thunkApi);
    }
  }
);
// Fetch All Individual Transactions
export const fetchAllIndividualTransactions = createAsyncThunk(
  'transactions/AllIndividualTransactions',
  async (id: string, thunkApi): Promise<unknown> => {
    try {
      const resp = await customFetch.get(`/users/${id}/transactions`);
      console.log(resp.data);

      return resp.data;
    } catch (error: any) {
      return checkUnauthorizedResponse(error, thunkApi);
    }
  }
);
// Fetch a Transactions by ID
export const fetchSingleTransaction = createAsyncThunk(
  'transactions/fetchSingleTransaction',
  async (id: string, thunkApi): Promise<unknown> => {
    try {
      const resp = await customFetch.get(`/transactions/${id}`);
      console.log(resp.data);

      return resp.data;
    } catch (error: any) {
      return checkUnauthorizedResponse(error, thunkApi);
    }
  }
);

// Registered Payment

export const registerPayment = createAsyncThunk(
  'transactions/registerPayment',
  async (id: any, thunkApi): Promise<unknown> => {
    const paymentReg: any = thunkApi.getState();
    const { amountPaid, reference, channel } = paymentReg.transactions;
    const data = { amountPaid, reference, channel };
    try {
      const resp = await customFetch.put(
        `/transactions/${id}/receive-payment`,
        data
      );
      console.log(resp.data);

      return resp.data;
    } catch (error: any) {
      // return checkUnauthorizedResponse(error, thunkApi);
      return thunkApi.rejectWithValue(error.response.data.errors[0].message);
    }
  }
);

const TransactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    handleTransactionsChange: (state: any, { payload }: any) => {
      const { name, value } = payload;
      console.log(typeof value);

      state[name] = value;
    },
    clearForms: (state) => {
      return { ...state, ...initialPaymentState };
    },
    changePage: (state, { payload }) => {
      state.page = payload;
    },
  },

  extraReducers: (builder) => {
    // fetch All Transactions
    builder.addCase(fetchAllTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchAllTransactions.fulfilled,
      (state: any, { payload }: any) => {
        const { data, total, success, pagination, page_count } = payload;

        state.data = data;
        state.total = total;
        state.page_count = page_count;
        state.pagination = pagination;
        state.success = success;
        state.numOfPages = Math.ceil(total / 20);
        state.isLoading = false;
      }
    );
    builder.addCase(
      fetchAllTransactions.rejected,
      (state, { payload }: any) => {
        state.isLoading = false;
        toast.error(payload);
      }
    );
    // fetch All Individual Transactions
    builder.addCase(fetchAllIndividualTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchAllIndividualTransactions.fulfilled,
      (state: any, { payload }: any) => {
        const { data, success } = payload;
        state.individualTransactions = data;
        state.success = success;
        state.isLoading = false;
      }
    );
    builder.addCase(
      fetchAllIndividualTransactions.rejected,
      (state, { payload }: any) => {
        state.isLoading = false;
        toast.error(payload);
      }
    );

    // Fetch a single Transaction
    builder.addCase(fetchSingleTransaction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchSingleTransaction.fulfilled,
      (state: any, { payload }: any) => {
        const { data } = payload;
        state.singleTransaction = data;
        state.isLoading = false;
      }
    );
    builder.addCase(
      fetchSingleTransaction.rejected,
      (state, { payload }: any) => {
        state.isLoading = false;
        toast.error(payload);
      }
    );

    // Register Payment
    builder.addCase(registerPayment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      registerPayment.fulfilled,
      (state: any, { payload }: any) => {
        state.paymentResponse = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(registerPayment.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
  },
});

export default TransactionsSlice.reducer;
export const { handleTransactionsChange, clearForms, changePage } =
  TransactionsSlice.actions;
