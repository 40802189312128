import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import {
  fetchAllProperties,
  setEditFalse,
} from 'features/properties/PropertiesSlice';
import { useEffect } from 'react';
import { DashboardLayout } from '@layouts/DashboardLayout';
import { DisplayProperties } from 'features/properties/DisplayProperties';
import { changePage } from 'features/properties/PropertiesSlice';
import { useNavigate } from 'react-router-dom';
import { Table, PageHeader, Pagination } from 'components';
import { getAllAmenities } from 'features/amenities/amenitiesSlice';
import { getAllCategories } from 'features/categories/categorySlice';
import { getAllKinds, getAllSpaces } from 'features/kind/kindSlice';

const Properties = () => {
  const dispatch = useAppDispatch();
  const { data, page, numOfPages, total } = useAppSelector(
    (store) => store.properties
  );

  const url = { page };

  const navigate = useNavigate();

  const rentIntent = data?.filter(
    (rent: DisplayProperties) => rent.intent === 'shortlet'
  );

  const salesIntent = data?.filter(
    (sales: DisplayProperties) => sales.intent === 'sale'
  );

  const leaseIntent = data?.filter(
    (lease: DisplayProperties) => lease.intent === 'long lease'
  );

  const getPublished = () => {
    dispatch(fetchAllProperties({ published: 'true', page }));
  };
  const getUnpublished = () => {
    dispatch(fetchAllProperties({ published: 'false', page }));
  };

  const getAllProperties = () => {
    dispatch(fetchAllProperties(url));
  };

  useEffect(() => {
    dispatch(fetchAllProperties(url));
    dispatch(getAllAmenities(null));
    dispatch(getAllCategories(null));
    dispatch(getAllKinds(null));
    dispatch(getAllSpaces(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <DashboardLayout>
      <div className='container-fluid'></div>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <PageHeader
              link1={rentIntent}
              link2={salesIntent}
              link3={leaseIntent}
              headerLink={true}
              title='Properties'
              total={total}
              getPublished={getPublished}
              getUnpublished={getUnpublished}
              getAllProperties={getAllProperties}
            />

            <button
              className='btn btn-primary mb-3'
              onClick={() => {
                navigate('/new-property');
                dispatch(setEditFalse());
              }}
            >
              New Property
            </button>

            <Table
              data={data}
              th={[
                'name',
                'intent',
                'published',
                'location',
                'price',
                'cycle',
                'posted by',
                'date created',
              ]}
              property={true}
            />
            {numOfPages > 1 && (
              <Pagination
                page={page}
                numOfPages={numOfPages}
                changePage={changePage}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
export default Properties;
