import { useState } from 'react';
import { DashboardLayout } from '@layouts/DashboardLayout';
import { NewPropertyLayout } from '@shared/NewPropertyLayout';

// import { useAppSelector } from '../../hooks/useAppSelector';
import {
  Step1,
  Step2,
  // Step3
} from './steps';

const NewProperty = () => {
  const [index, setIndex] = useState<number>(0);
  const [totalSteps, setTotalSteps] = useState<number>(0);

  const onPrev = () => {
    setIndex(index - 1);
  };

  const onNext = () => {
    setIndex(index + 1);
  };

  return (
    <DashboardLayout>
      <div className='container-fluid'></div>
      <div className='container mt-5 px-5'>
        <NewPropertyLayout index={index} setTotalSteps={setTotalSteps}>
          <Step1
            onPrev={onPrev}
            onNext={onNext}
            index={index}
            totalSteps={totalSteps}
          />
          <Step2
            onPrev={onPrev}
            onNext={onNext}
            index={index}
            totalSteps={totalSteps}
          />
          {/* <Step3
						onPrev={onPrev}
						onNext={onNext}
						index={index}
						totalSteps={totalSteps}
					/> */}
        </NewPropertyLayout>
      </div>
    </DashboardLayout>
  );
};

export default NewProperty;
