import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { DashboardLayout } from '@layouts/DashboardLayout';

import {
  CreateCategories,
  deleteCategory,
  // editCategory,
  getAllCategories,
  // handleCategoryChange,
} from 'features/categories/categorySlice';
import { Button, Form, Table } from 'react-bootstrap';
import { PageHeader } from 'components';

const Categories = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector((state) => state.categories);

  const [title, setTitle] = useState<string | ''>('');
  const [description, setDescription] = useState<string | ''>('');

  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const handleRowClick = (rowId: any) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };
  // Fetch categories on component mount
  useEffect(() => {
    dispatch(getAllCategories({}));
  }, [dispatch]);

  // const handleChange = (event: any) => {
  //   const { name, value } = event.target;
  //   dispatch(handleCategoryChange({ name, value }));
  // };
  const handleCreate = () => {
    dispatch(CreateCategories({ title, description }));
    setTitle('');
    setDescription('');
  };
  // const handleEdit = (id: string) => {
  //   dispatch(editCategory(id));
  // };
  const handleDelete = (id: string) => {
    dispatch(deleteCategory(id));
  };

  return (
    <DashboardLayout>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <PageHeader headerLink={false} title='Categories' />

            <div className='container m-4'>
              <Form>
                <Form.Group controlId='formTitle'>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter title'
                    name='title'
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group controlId='formDescription'>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    placeholder='Enter description'
                    name='description'
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </Form.Group>

                <Button
                  variant='primary'
                  className='my-4'
                  onClick={handleCreate}
                >
                  Create Category
                </Button>
              </Form>

              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((category: any) => (
                        <React.Fragment key={category.id}>
                          <tr onClick={() => handleRowClick(category.id)}>
                            <td>{category.title}</td>
                            <td>{category.description}</td>

                            <td className='text-end'>
                              <div className='dropdown'>
                                <button
                                  className=' dropdown-ellipses dropdown-toggle btn btn-link'
                                  type='button'
                                  id='dropdownMenuButton1'
                                  data-bs-toggle='dropdown'
                                  aria-expanded='false'
                                >
                                  <i className='fe fe-more-vertical '></i>
                                </button>
                                <div className='dropdown-menu dropdown-menu-end'>
                                  <button
                                    className='dropdown-item btn btn-link'
                                    onClick={() => handleDelete(category.id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Categories;
