export const paginate = (transactions: any, itemsPerPage: number = 10) => {
  const numberOfPages = Math.ceil(transactions.length / itemsPerPage);

  const newData = Array.from({ length: numberOfPages }, (_, index) => {
    const start = index * itemsPerPage;
    return transactions.slice(start, start + itemsPerPage);
  });

  return newData;
};

export const prev = (setPage: any, pageData: any) => {
  setPage((oldNumber: number) => {
    let previousNum;
    previousNum = oldNumber - 1;
    if (previousNum < 0) {
      previousNum = pageData.length - 1;
    }

    return previousNum;
  });
};

export const next = (setPage: any, pageData: any) => {
  setPage((oldNumber: number) => {
    let nextNum;
    nextNum = oldNumber + 1;
    if (nextNum > pageData.length - 1) {
      nextNum = 0;
    }
    return nextNum;
  });
};

export const handleChange = (setPage: any, index: number) => {
  setPage(index);
};
