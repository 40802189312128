import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import {
  getAllTransactionThunk,
  getTransactionByIDThunk,
  getTransactionByWalletIDThunk,
  getWalletByIDThunk,
  withdrawRequestThunk,
} from './walletThunk';

interface AsyncState {
  isLoading: boolean;
}

interface AuthState extends AsyncState {
  allTransactions?: any | null;
  walletInfo?: any | null;
  walletTransaction?: any | null;
  withdrawRequest?: any | null;
  singleTransaction?: any | null;
  page: number;
}

const initialState: AuthState = {
  isLoading: false,
  walletInfo: null,
  walletTransaction: null,
  allTransactions: null,
  withdrawRequest: null,
  singleTransaction: null,
  page: 0,
};

// GET ALL TRANSACTIONS
export const getAllTransactions = createAsyncThunk(
  'wallet/getAllTransactions',
  getAllTransactionThunk
);

// GET WALLET BY ID
export const getWalletById = createAsyncThunk(
  'wallet/getWalletById',
  getWalletByIDThunk
);

// GET TRANSACTIONS BY WALLET ID
export const getTransactionsByWalletId = createAsyncThunk(
  'wallet/getTransactionsByWalletId',
  getTransactionByWalletIDThunk
);
// GET TRANSACTIONS BY WALLET ID
export const getTransactionsById = createAsyncThunk(
  'wallet/getTransactionsById',
  getTransactionByIDThunk
);

// WITHDRAWAL REQUEST
export const withdrawRequest = createAsyncThunk(
  'wallet/withdrawRequest',
  withdrawRequestThunk
);

const WalletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    clearWallet: (state) => {
      state.walletInfo = null;
    },
    setPage: (state, { payload }: any) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch All Transactions
    builder.addCase(getAllTransactions.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAllTransactions.fulfilled, (state, { payload }: any) => {
      state.isLoading = false;
      state.allTransactions = payload;
    });

    builder.addCase(getAllTransactions.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Fetch Wallet by ID
    builder.addCase(getWalletById.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getWalletById.fulfilled, (state, { payload }: any) => {
      state.isLoading = false;
      state.walletInfo = payload;
    });

    builder.addCase(getWalletById.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // fetch transactionsByWalletID
    builder.addCase(getTransactionsByWalletId.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      getTransactionsByWalletId.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.walletTransaction = payload;
      }
    );

    builder.addCase(
      getTransactionsByWalletId.rejected,
      (state, { payload }: any) => {
        state.isLoading = false;
        toast.error(payload);
      }
    );
    // fetch transactionsByID
    builder.addCase(getTransactionsById.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getTransactionsById.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.singleTransaction = payload;
    });

    builder.addCase(getTransactionsById.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // withdraw request
    builder.addCase(withdrawRequest.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(withdrawRequest.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.withdrawRequest = payload;
    });

    builder.addCase(withdrawRequest.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
  },
});

export const { clearWallet, setPage } = WalletSlice.actions;
export default WalletSlice.reducer;
