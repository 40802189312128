import { useEffect } from 'react';
import { useAppSelector } from '../hooks/useAppSelector';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { changePage, fetchAllReviews } from 'features/reviews/ReviewSlice';
import { DashboardLayout } from '@layouts/DashboardLayout';
import { PageHeader, Pagination, Table } from 'components';

const Reviews = () => {
  const dispatch = useAppDispatch();

  const { data, numOfPages, page } = useAppSelector((store) => store.reviews);

  useEffect(() => {
    dispatch(fetchAllReviews(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <div className='container-fluid'></div>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <PageHeader headerLink={false} title='Reviews' />

            <Table
              data={data}
              th={[
                'id',
                'customer name',
                'review',
                'rating',
                'review Date',
                'property id',
                'review updated',
                'status',
                'actions',
              ]}
              reviews
            />
            {numOfPages! > 1 && (
              <Pagination
                page={page}
                numOfPages={numOfPages}
                changePage={changePage}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Reviews;
