import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppRouter } from './routes';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AppRouter />
      <ToastContainer position='top-center' />
    </BrowserRouter>
  );
};

export default App;
