import { useAppSelector, useAppDispatch } from '../hooks';
import { DashboardLayout } from '@layouts/DashboardLayout';
import { PageHeader, Pagination, Table } from 'components';
import { useEffect } from 'react';
import { getAllUser, changePage, searchUser } from 'features/users/UserSlice';

export const LandLords = () => {
  const { searchKey, data, page, numOfPages } = useAppSelector(
    (store) => store.user
  );
  const dispatch = useAppDispatch();
  const url = { role: 'landlord', page: page };

  useEffect(() => {
    if (searchKey) {
      dispatch(searchUser(searchKey));
    } else {
      dispatch(getAllUser(url));
    }
    // if (!searchKey) {
    //   dispatch(getAllUser(url));
    // }
    // dispatch(getAllUser(url));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchKey]);
  return (
    <DashboardLayout>
      <div className='container-fluid'></div>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <PageHeader headerLink={false} title='LandLords' />

            <Table
              data={data}
              th={[
                'name',
                'email',
                'phone',
                'account Status',
                'verification Status',
                'date Joined',
              ]}
              user
            />
          </div>

          {numOfPages > 1 && (
            <Pagination
              page={page}
              numOfPages={numOfPages}
              changePage={changePage}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};
