import { Routes, Route } from 'react-router-dom';
import {
  Dashboard,
  Profile,
  SignIn,
  // SignUp,
  ForgotPassword,
  ProtectedRoute,
  LandLords,
  Admins,
  Agents,
  Customers,
  Properties,
  Transactions,
  SingleProperty,
  NewProperty,
  Reviews,
  WalletTransactions,
  WithdrawRequest,
  SingleTransaction,
  SingleReview,
  Categories,
  Kind,
  Amenities,
} from '@pages/index';
// import { LandLords } from '@pages/Landlords';

export const AppRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<ProtectedRoute />}>
        <Route path='/summary/' element={<Dashboard />} />
        <Route path='/properties/' element={<Properties />} />
        <Route path='/properties/:id' element={<SingleProperty />} />
        <Route path='/new-property' element={<NewProperty />} />
        <Route path='/edit-property/:id' element={<NewProperty />} />
        <Route index element={<Dashboard />} />
        <Route path='/user/:id' element={<Profile />} />
        <Route path='/landlords/' element={<LandLords />} />
        <Route path='/admins/' element={<Admins />} />
        <Route path='/agents/' element={<Agents />} />
        <Route path='/customers/' element={<Customers />} />
        <Route path='/transactions/' element={<Transactions />} />
        <Route path='/reviews/' element={<Reviews />} />
        <Route path='/wallet/transactions' element={<WalletTransactions />} />
        <Route path='/wallet/withdraw-request' element={<WithdrawRequest />} />
        <Route path='/transactions/:id' element={<SingleTransaction />} />
        <Route path='/review/:id' element={<SingleReview />} />
        <Route path='/web/categories' element={<Categories />} />
        <Route path='/web/kind' element={<Kind />} />
        <Route path='/web/amenities' element={<Amenities />} />
      </Route>
      <Route path='sign-in' element={<SignIn />} />
      {/* <Route path='sign-up' element={<SignUp />} /> */}
      <Route path='forgot-password' element={<ForgotPassword />} />
    </Routes>
  );
};
