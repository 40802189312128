// import { useAppDispatch } from '../hooks';
import { Link } from 'react-router-dom';

import moment from 'moment';

const ReviewTableData = ({ data }: any) => {
  // const dispatch = useAppDispatch();

  return (
    <tbody className='list fs-base'>
      {data?.map((info: any) => {
        const {
          user,
          rating,
          review,
          id,
          property,
          approved,
          updatedAt,
          createdAt,
        } = info;
        return (
          <tr key={id}>
            <td>
              <div className='form-check'>
                <input
                  className='form-check-input list-checkbox'
                  id='listCheckboxOne'
                  type='checkbox'
                />
                <label
                  className='form-check-label'
                  htmlFor='listCheckboxOne'
                ></label>
              </div>
            </td>
            <td>
              <Link to={`/review/${id}`}>{id}</Link>
            </td>
            <td>
              <Link to={`/user/${user?.id}`}>{user?.name}</Link>
            </td>
            <td>
              <a href='#!' data-bs-toggle='modal' data-bs-target='#reviewModal'>
                <span className='item-intent'>
                  {review?.length > 0 && review.slice(0, 20) + '...'}
                </span>
              </a>

              {/* <!-- Modal --> */}
              <div
                className='modal fade'
                id='reviewModal'
                tabIndex={-1}
                role='dialog'
                aria-hidden='true'
              >
                <div className='modal-dialog' role='document'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h3 className='modal-title' id='reviewModalLabel'>
                        {user['name']}
                      </h3>
                    </div>
                    <div className='modal-body text-wrap'>
                      <p className='text-secondary fw-bold'>{review}</p>
                    </div>
                    <div className='modal-footer d-flex  justify-content-between'>
                      <div>
                        <p>
                          Status:{' '}
                          {approved === false ? (
                            <span className='badge bg-danger-soft'>
                              Not Approved
                            </span>
                          ) : (
                            <span className='badge bg-success-soft'>
                              Approved
                            </span>
                          )}
                        </p>
                      </div>
                      <div>
                        <button
                          type='button'
                          className='btn btn-secondary'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <span className='item-intent'>{rating}</span>
            </td>
            <td>
              <span className='item-bedrooms'>
                {moment(createdAt).format('MMM Do YY')}
              </span>
            </td>
            <td>
              {/* <span className='item-location'>{property}</span> */}
              <Link to={`/properties/${property}`}>{property}</Link>
            </td>
            <td>
              <span className='item-cycle'>
                {moment(updatedAt).format('MMM Do YY')}
              </span>
            </td>
            <td>
              <span className='item-price'>
                {approved === false ? (
                  <div className='badge bg-danger-soft'>Not Approved</div>
                ) : (
                  <div className='badge bg-success-soft'>Approved</div>
                )}
              </span>
            </td>

            <td className='text-end'>
              <div className='dropdown'>
                {/* <a
                  className='dropdown-ellipses dropdown-toggle'
                  href='#!'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <i className='fe fe-more-vertical'></i>
                </a> */}

                <button
                  className=' dropdown-ellipses dropdown-toggle btn btn-link'
                  id='dropdownMenuButton1'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                  type='button'
                >
                  <i className='fe fe-more-vertical'></i>
                </button>
                <div className='dropdown-menu dropdown-menu-end'>
                  <Link to={`/properties/${id}`} className='dropdown-item'>
                    Edit
                  </Link>
                  <button
                    className='dropdown-item btn btn-link'
                    onClick={() => {
                      // dispatch(deleteProperty(id));
                      console.log('hello');
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default ReviewTableData;
