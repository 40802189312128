// import { DisplayProperties } from 'features/properties/DisplayProperties';
// import { TableHead } from 'interface/tableHead.interface';
// import { Link } from 'react-router-dom';

import {
  SearchBar,
  PropertyTableData,
  UsersTableData,
  WalletTransactionTableData,
  UserSearchBar,
} from 'components';
import ReviewTableData from './ReviewTableData';
import TransactionTableData from './TransactionsTableData';
// import img from '../images/download.png';
const Table = ({ data, th, property, user, reviews, transactions }: any) => {
  return (
    <div className='tab-content'>
      <div
        className='tab-pane fade show active'
        id='contactsListPane'
        role='tabpanel'
        aria-labelledby='contactsListTab'
      >
        <div
          className='card'
          data-list='{"valueNames": ["item-name", "item-title", "item-email", "item-phone", "item-score", "item-company"], "page": 10, "pagination": {"paginationClass": "list-pagination"}}'
          id='contactsList'
        >
          {property ? (
            <SearchBar property={property} />
          ) : user ? (
            <UserSearchBar />
          ) : (
            ''
          )}
          {data?.length !== 0 ? (
            <div className='table-responsive'>
              <table className='table table-sm table-hover table-nowrap card-table'>
                <thead>
                  <tr>
                    <th>
                      <div className='form-check mb-n2'>
                        <input
                          className='form-check-input list-checkbox-all'
                          id='listCheckboxAll'
                          type='checkbox'
                        />
                        <label
                          className='form-check-label'
                          htmlFor='listCheckboxAll'
                        ></label>
                      </div>
                    </th>

                    {th?.map((h: string, i: number) => {
                      return (
                        <th key={i}>
                          <a
                            className='list-sort text-muted'
                            data-sort={`item-${h}`}
                            href='#!'
                          >
                            {h.toUpperCase()}
                          </a>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                {property ? (
                  <PropertyTableData data={data} property={property} />
                ) : user ? (
                  <UsersTableData data={data} />
                ) : transactions ? (
                  <TransactionTableData data={data} />
                ) : reviews ? (
                  <ReviewTableData data={data} />
                ) : (
                  <WalletTransactionTableData data={data} />
                )}
              </table>
            </div>
          ) : (
            <h3 className='py-4 text-center'>
              {property
                ? 'No Property Matched Your Search'
                : user
                ? 'No User Matched Your Search'
                : transactions
                ? 'No Recent Transactions'
                : reviews
                ? 'There are no reviews to display'
                : ''}
            </h3>
          )}
        </div>
      </div>
    </div>
  );
};
export default Table;
