import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateProperty } from './EditProperty.interface';
import { toast } from 'react-toastify';

// import { getAuthFromLocalStorage } from '@utils/localstorage';
import { DisplayProperties } from './DisplayProperties';
import {
  addPropertyImageThunk,
  createPropertyThunk,
  deletePropertyThunk,
  getAllPropertiesThunk,
  getPendingApprovalThunk,
  singlePropertyThunk,
  togglePublishThunk,
  updatePropertyThunk,
} from './PropertyThunk';

interface AsyncState {
  isLoading: boolean;
}

interface AuthState extends AsyncState, CreateProperty {
  data?: DisplayProperties[] | null;
  singleProperty?: DisplayProperties | null;
  success?: boolean;
  editSuccess?: boolean | null;
  total?: number;
  propertyPendingApproval?: number;
  numOfPages: number;
  newPropertyId?: string;
  page: number;
  propertySummary?: any;
  intent?: string;
  search?: string;
  editMode?: boolean;
  amenities?: any;
  // category?: string;
  bedrooms?: number;
  'location.city'?: string;
}

const initialCreatePropertyState = {
  id: '',
  title: '',
  summary: '',
  category: '',
  kind: '',
  space: '',
  // amenities: {
  //   _id: '',
  //   title: '',
  //   offers: [],
  // },
  amenities: [],
  affiliation: '',
  flood_meter: '',
  rules: '',
  toilets: 0,
  address: '',
  price: 0,
  currency: '',
  description: '',
  intent: '',
  cycle: '',
  guests: 0,
  beds: 0,
  garages: 0,
  bedrooms: 0,
  bathrooms: 0,
  floor: 0,
  area: '',
  virtual_tour_url: '',
  video_url: '',
};
const initialFilterState = {
  intent: '',
  search: '',
  // category: '',
  'location.city': '',

  bedroom: 1,
};
const initialState: AuthState = {
  data: null,
  singleProperty: null,
  editMode: false,
  success: false,
  isLoading: false,
  propertySummary: {},
  total: 0,
  propertyPendingApproval: 0,
  page: 1,
  newPropertyId: '',
  numOfPages: 1,
  ...initialFilterState,
  ...initialCreatePropertyState,
};

// Fetch all properties

export const fetchAllProperties = createAsyncThunk(
  'properties/allProperties',
  getAllPropertiesThunk
);

// fetch single property

export const getProperty = createAsyncThunk(
  'property/singleProperty',
  singlePropertyThunk
);

// Add property Image
export const addPropertyImage = createAsyncThunk(
  'property/addPropertyImage',
  addPropertyImageThunk
);

// Create Property
export const createProperty = createAsyncThunk(
  'property/createProperty',
  createPropertyThunk
);
// Update Property
export const updateProperty = createAsyncThunk(
  'property/updateProperty',
  updatePropertyThunk
);

// Delete Property
export const deleteProperty = createAsyncThunk(
  'property/deleteProperty',
  deletePropertyThunk
);

// Publish Property
export const togglePublish = createAsyncThunk(
  'property/togglePublish',
  togglePublishThunk
);

// Get All Properties pending Approvals
export const getPendingApproval = createAsyncThunk(
  'property/getPropertyPendingApproval',
  getPendingApprovalThunk
);
const PropertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    changePage: (state, { payload }) => {
      state.page = payload;
    },
    handlePropertyChange: (state: any, { payload }: any) => {
      const { name, value } = payload;
      // console.log(typeof value);

      if (name === 'location') {
        state.location.city = value;
        return;
      }
      state[name] = value;
    },
    clearFilters: (state) => {
      return { ...state, ...initialFilterState };
    },
    handleEdit: (state: any, { payload }) => {
      return { ...state, editMode: true, ...payload };
    },
    setEditFalse: (state: any) => {
      // state.editMode = false;
      return { ...state, editMode: false, ...initialCreatePropertyState };
    },
  },

  extraReducers: (builder) => {
    // fetch All Properties
    builder.addCase(fetchAllProperties.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchAllProperties.fulfilled,
      (state: any, { payload }: any) => {
        const { summary, data, pagination, success, total } = payload;

        state.data = data;
        state.summary = summary;
        state.pagination = pagination;
        state.success = success;
        state.total = total;
        state.numOfPages = Math.ceil(total / 20);
        state.isLoading = false;
      }
    );
    builder.addCase(fetchAllProperties.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // single property
    builder.addCase(getProperty.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProperty.fulfilled, (state: any, { payload }: any) => {
      state.singleProperty = payload;
      state.isLoading = false;
    });
    builder.addCase(getProperty.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    //Create Property
    builder.addCase(createProperty.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(
      createProperty.fulfilled,
      (state: any, { payload }: any) => {
        state.isLoading = false;
        state.success = true;

        state.newPropertyId = payload.id;
        toast.success('Property Added Successfully');
      }
    );
    builder.addCase(createProperty.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      state.success = false;
      toast.error(payload);
    });

    //Update Property
    builder.addCase(updateProperty.pending, (state) => {
      state.isLoading = true;
      state.editSuccess = false;
    });
    builder.addCase(updateProperty.fulfilled, (state: any) => {
      state.isLoading = false;
      state.editSuccess = true;
      state.success = true;
      toast.success('Updated Successfully');
    });
    builder.addCase(updateProperty.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      state.success = false;
      state.editSuccess = false;
      toast.error(payload);
    });

    //Delete Property
    builder.addCase(deleteProperty.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProperty.fulfilled, (state: any) => {
      state.isLoading = false;
      toast.success('Property Deleted Successfully');
    });
    builder.addCase(deleteProperty.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Toggle Publish Property
    builder.addCase(togglePublish.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(togglePublish.fulfilled, (state: any) => {
      const status = state.singleProperty.published;
      state.isLoading = false;
      toast.success(
        `${
          status
            ? 'Property Unpublished Successfully'
            : 'Property Published Successfully'
        }`
      );
    });
    builder.addCase(togglePublish.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    //Add property Image
    builder.addCase(addPropertyImage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addPropertyImage.fulfilled, (state: any) => {
      state.isLoading = false;
      toast.success('Image Added Successfully');
    });
    builder.addCase(addPropertyImage.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // Get  All properties pending approvals
    builder.addCase(getPendingApproval.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPendingApproval.fulfilled, (state, { payload }: any) => {
      const { total } = payload;
      // state.isLoading = false;
      state.propertyPendingApproval = total;
    });
    builder.addCase(getPendingApproval.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      toast.error(payload);
    });
  },
});
export const {
  changePage,
  handlePropertyChange,
  clearFilters,
  handleEdit,
  setEditFalse,
} = PropertiesSlice.actions;
export default PropertiesSlice.reducer;
