import customFetch, { checkUnauthorizedResponse } from '../../utils/axios';

// Get All Transaction
export const getAllTransactionThunk = async (thunkApi: any) => {
  try {
    const resp = await customFetch.get('/wallet-transactions');
    console.log(resp.data.data);
    return resp.data.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};

// Get Wallet by ID
export const getWalletByIDThunk = async (id: string, thunkApi: any) => {
  try {
    const resp = await customFetch.get(`/wallets/${id}`);
    console.log(resp.data.data);
    return resp.data.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};

// Get Transaction By Wallet ID
export const getTransactionByWalletIDThunk = async (
  id: string,
  thunkApi: any
) => {
  try {
    const resp = await customFetch.get(`/wallet-transactions/wallet/${id}`);
    console.log(resp.data.data);
    return resp.data.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};
// Get Transaction By  ID
export const getTransactionByIDThunk = async (id: string, thunkApi: any) => {
  try {
    const resp = await customFetch.get(`/wallet-transactions/${id}`);
    console.log(resp.data.data);
    return resp.data.data;
  } catch (error: any) {
    return checkUnauthorizedResponse(error, thunkApi);
  }
};

// Withdraw Request
export const withdrawRequestThunk = async (
  { id, data }: any,
  thunkApi: any
) => {
  try {
    const resp = await customFetch.post(`/wallets/${id}`, data);
    console.log(resp.data);
    return resp.data.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data.errors[0].message);
  }
};
